import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import "../../../style/home.css"
import { AlliesCarousel } from '../../elements'
import nuevavoz from "../../../assets/imgs/allies/nuevavoz.png"
import n12 from "../../../assets/imgs/allies/n12.png"
import mani from "../../../assets/imgs/allies/mani.png"
import icum from "../../../assets/imgs/allies/icum.png"

const allies = [
    {
        imgs: n12,
        name: "N12",
        link: "https://www.n12.mx"
    },
    {
        imgs: nuevavoz,
        name: "Nueva Voz",
        link: "https://nuevavozpuebla.com/"
    },
    {
        imgs: mani,
        name: "Maniquies & Display",
        link: ""
    },
]
function Allies() {
    return (
        <>
            <Container className="d-md-none d-flex flex-column align-items-center" fluid >
                <h1 className="mt-5 ml-2 font-weight-light"> Aliados.</h1>
                <AlliesCarousel />
            </Container>
            <Container className=" flex-column justify-content-center " fluid>
                <Row className="mt-5">
                    <Col md={1} sm={12}></Col>
                    <Col md={11} sm={12}>
                        <h2 className="d-md-flex d-none font-weight-light"> Aliados.</h2>
                    </Col>
                    <Col md={1} sm={12}></Col>
                    <Col md={11} sm={12}>
                    {allies.map(({ imgs, name, link }, i) => (
                            <a href={link} target="_blank" rel="noopener noreferrer">
                                <img src={imgs} alt={name} className=" img-fluid img_allies" />
                            </a>
                    ))}
                    </Col>
                    <Col md={1}></Col>
                </Row>
            </Container>
        </>
    )
}

export default Allies 