import React from 'react';
import { Container, Row, Col } from 'reactstrap'
import nov_05 from "../../../../assets/imgs/landing2022/foodesign/05_nov.jpg"
import nov_06 from "../../../../assets/imgs/landing2022/foodesign/06_nov.jpg"
import nov_08 from "../../../../assets/imgs/landing2022/foodesign/08_nov.jpg"
import nov_09 from "../../../../assets/imgs/landing2022/foodesign/09_nov.jpg"
import nov_10 from "../../../../assets/imgs/landing2022/foodesign/10_nov.jpg"

import "../../../../style/home.css"
const food_info = [
    {
        imgs: nov_05,
        title: "Family Meal",
        date: "Sábado 5 noviembre 2022 / 14:00 hrs.",
        text: "Casona María",
        place: "Calle 3 Oriente 1414, Barrio de Analco, Puebla",

    },
    {
        imgs: nov_06,
        title: "Family Meal",
        date: "Domingo 6 noviembre 2022 / 14:00 hrs.",
        text: "Casona María / Vica “Cocina de un Valle” ",
        place: "3 Ote. 1414, Barrio de Analco, Puebla, 2 Ote. 7, Centro histórico de Atlixco, Atlixco, Puebla",
    },
    {
        imgs: nov_08,
        title: "Family Meal",
        date: "Miércoles 8 noviembre 2022 / 14:30 hrs.",
        text: "Vica “Cocina de un Valle”",
        place: "2 Oriente 7, Centro histórico de Atlixco, Atlixco, Puebla.",
    },
    {
        imgs: nov_09,
        title: "Family Meal",
        date: "Jueves 9 noviembre 2022 / 14:30 hrs.",
        text: "Casona María",
        place: "Calle 3 Oriente 1414, Barrio de Analco, Puebla",
    },
    {
        imgs: nov_10,
        title: "Family Meal",
        date: "Viernes 10 noviembre 2022 / 14:30 hrs.",
        text: "Casona María",
        place: "Calle 3 Oriente 1414, Barrio de Analco, Puebla",
    }
]
function PueblaFood() {
    return (
        <>
            <Container className="bg-fooddesign" fluid>

            </Container>
            <Container className="d-flex flex-column" fluid>
                <Row className="my-md-5 my-3">
                    <Col md={1}></Col>
                    <Col >
                        <h6 className="mt-4 font-weight-light text-dark ">
                            Puebla Foodesign
                        </h6>
                    </Col>
                </Row>
                <Row  >
                    <Col md={1}></Col>
                    <Col  >
                        <h4 className="mb-5" >
                            Food Design es el origen que visibiliza, la mesa de Design Week Puebla esta llena, un Family meal montado por Casa Bacuuza, Vica Cocina de un Valle, Museo de la Cocina Mexicana Itinerante y Casona María. El estudio del Territorio Transversal nos lleva a un ingrediente: el Chapulín, alimento que transforma al Territorio en lo cultural, lo social y lo económico. Innova la Gastronomía.<br /><br />

                            La caza de este insecto comienza a las tres de la mañana, al cuarto para las siete se convierte en un mando de mujeres, quienes toman el producto, lo escogen o espulgan, lo lavan, lo ciernen y clasifican, lo cocinan y comercializan en la región centro sur del país. Un producto poblano para la Cocina Mexicana.<br /><br />

                            Cocinar el Chapulín es compartir, transmitir, transformar al ingrediente y descifrarlo en las recetas, la mezcla perfecta de cada cocina en su propia región, en cada lugar y en su propio espacio. Hoy ellos generan la experiencia de Food Design: Abraham, Francisco, Julio y Víctor.
                        </h4>
                    </Col>
                    <Col md={1}></Col>
                </Row>

            </Container>
            <Container fluid>
                <Row md={4} className="my-5 d-flex justify-content-center px-2">
                    {food_info.map(({ title, text, imgs, date, place }, i) => (
                        <div key={i} className="m-2">
                            <img src={imgs} alt={title} className=" img-fluid custom-landing-img" />
                            <div>
                                <h3 className="font-weight-light">{title}</h3>
                                <h4 className="font-weight-light">{date}</h4>
                                <h4 className="font-weight-light">{text}</h4>
                                <p className="font-weight-light">{place}</p>
                            </div>
                        </div>
                    ))}
                </Row>
            </Container>
        </>
    )
}

export default PueblaFood