import React from 'react';
import { Layout } from '../components'
import { UsSecond } from "../components/sections/us"
import "../style/home.css"

function SecondEditionUs(){
    return(
        <Layout>
            <UsSecond/>
        </Layout>
    )
}

export default SecondEditionUs;
