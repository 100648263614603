import React from 'react';
import { Container, Row, Col } from 'reactstrap'
import "../../../../style/home.css"

function DesignNight() {
    return (
        <>
            <Container className="bg-designnight d-flex flex-column justify-content-center " fluid>
            </Container>
            <Container className="my-5" fluid>
                <Row className="mt-5">
                    <Col md={1}></Col>
                    <Col >
                        <h6>
                            Design Night
                        </h6>
                    </Col>
                </Row>
                <Row>
                    <Col md={1}></Col>
                    <Col >
                        <h4 className="my-md-3 my-3">
                            Es la noche de las personas que formamos parte de la semana del diseño: aliados estratégicos,
                            artesanos, artistas, diseñadores y staff, nos reunimos en el lugar -espacio arquitectónico dentro o fuera
                            de la ciudad- en donde nos sentamos a la mesa para dialogar, contar nuestros retos, degustar, generar
                            comunidad y fortalecer nuestro pensamiento.<br /><br />
                            En el evento en el que año con año buscamos reconocer a una persona, institución, colectivo,
                            cooperativa o embajador que aporta a la industria creativa y sobre todo incentiva a la inversión y el
                            crecimiento de la economía naranja (economía creativa) en la región. Design Night eres tú, somos
                            todos, sé parte de esta noche
                        </h4>
                    </Col>
                    <Col md={1}></Col>
                </Row>
            </Container>
        </>
    )
}

export default DesignNight