import React from "react";
import { Container, Row, Col } from "reactstrap";

function Loteria() {
    return (
        <>
            <Container className="bg-loteria" fluid>

            </Container>
            <Container className="px-md-0 px-4" fluid>
                <Row className="my-md-5 my-3">
                    <Col md={1}></Col>
                    <Col >
                        <h6 className="mt-4 font-weight-light text-dark ">
                            Lotería Poblana
                        </h6>
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col md={1}></Col>
                    <Col >
                        <h5>
                            El juego, en las sociedades humanas, es tan esencial como la reflexión y el trabajo; para el historiador Johan Huizinga es parte fundamental del desarrollo de la cultura, incluso puede considerarse como su fundamento. Por este motivo, juegos de carácter universal como la lotería en el contexto mexicano, se ha convertido en una tradición que refleja nuestra cultura e idiosincrasia. En el caso del Estado de Puebla, el imaginario colectivo se condensa en un solo juego: 54 tarjetas que expresan el mosaico de la identidad cultural de las ciudades, municipios, pueblos, colonias y barrios que constituyen el Estado, pues, desde su fundación – varias de acuerdo a la historia -, ha sido habitada por hombres y mujeres de culturas diversas.<br /><br />
                            Lotería Poblana es la experiencia en monumentos históricos tangibles e intangibles, memorias y saberes de Puebla, el sabor de la gastronomía, la monumentalidad de la Sierra Nevada, la Sierra Mixteca, la Sierra Nororiental y la Angelópolis, el cotidiano de poblanos en los pueblos originarios y en las ciudades. Un juego tradicional, lúdico y de aprendizaje para generar tejido social inspirado en los conceptos fundamentales de El Origen para Trascender: cultura, tradición, paisaje y gastronomía.<br /><br />
                            <span className="font-weight-bold">Elmer Sosa. 1978, Puebla. </span>Maestro en Mercadotecnia, Diseñador Gráfico, Ilustrador y Profesor, fue Beneficiario del
                            Programa de Estímulo a la Creación y el Desarrollo Artístico 2015-2016 (Puebla), es miembro de la Asociación
                            Mexicana de Ilustradores, Embajador del Diseño Latino por la Universidad de Palermo Argentina. Ganador del Premio
                            a! Diseño, 2do Premio en Golden Turtle 2017, Rusia, Ganador „Mut Zur Wut“ en Alemania 2017, 2012 y 2011, 1er lugar
                            International Poster Competition FUKUDA! Rusia 2016, Mención de Honor en la Virtual Biennale Prague 2016, 1er
                            lugar Festival Internacional de la Imagen, Premio Quórum 2013 y Mención de Honor en la 18 Bienal Internacional de
                            Humorismo Gráfico en Cuba, 2013. Su trabajo ha sido exhibido en Perú, Bolivia, Venezuela, Cuba, Francia, Ucrania,
                            España, Estados Unidos, Rusia, República Checa, Japón y China. Actualmente es Director de Arte de Yomi Yomi Studio,
                            Caricaturista Político de Diario Cambio en Puebla, Ilustrador para diferentes casas editoriales y Profesor en diferentes
                            Universidades.<br /><br />
                            <span className="font-weight-bold">Inauguración:</span> Miércoles 3 noviembre 2021 a las 19:00 hrs. Traspatio (5 sur, 705, Centro histórico de Puebla.) <br />
                            Museo Regional de Cholula: Calle 14 Poniente 307, San Juan Aquiahuac, San Andrés Cholula, Puebla
                        </h5>
                    </Col>
                    <Col md={1}></Col>
                </Row>

            </Container>
        </>
    )
}

export default Loteria