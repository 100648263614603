import React from 'react';
import { Container, Row, Col } from 'reactstrap'
import { Link } from "react-router-dom"
import "../style/footer.css"
import fb from "../assets/imgs/socials/facebook.svg"
import instagram from "../assets/imgs/socials/instagram.svg"
import twitter from "../assets/imgs/socials/twitter.svg"
import linkedin from "../assets/imgs/socials/linkedin.svg"

import logo from "../assets/imgs/logo.png"

const mapsite = [
    {
        to: "/",
        name: "Calendario 2023",
    },
    {
        to: "/cuarta-edicion/",
        name: "Exhibiciones",
    },
    {
        to: "/nosotros/",
        name: "Nosotros",
    },
    {
        to: "/aliados/",
        name: "Aliados",
    },
    {
        to: "/contacto/",
        name: "Contacto",
    },
]

const socials = [
    {
        imgs: instagram,
        name: "Design Week Puebla Instagram",
        link: "https://www.instagram.com/designweekpuebla/"
    },
    {
        imgs: fb,
        name: "Design Week Puebla Facebook",
        link: "https://www.facebook.com/designweekpuebla"
    },
    {
        imgs: twitter,
        name: "Design Week Puebla Twitter",
        link: "https://twitter.com/designweekpue"
    },
    {
        imgs: linkedin,
        name: "Design Week Puebla Linkedin",
        link: "https://www.linkedin.com/company/designweekpuebla"
    },
]


function Footer() {
    return (
        <Container className="bg_footer d-flex flex-column " fluid>
            <Row className="pt-5">
                <Col md={1} sm={0}></Col>
                <Col md={3} sm={0} className="d-md-flex flex-column d-none">
                    <h2 className=" font-weight-light">
                        DWP
                    </h2>
                    {mapsite.map(({ to, name, external }, i) => (
                        <a
                            className="px-0 custom-underline text-dark"
                            tag={external ? "a" : Link}
                            to={to}
                            href={to}>
                            <h5 className="mt-1" >
                                {name}
                            </h5>
                        </a>
                    ))}
                </Col>
                <Col md={4} sm={6}  >
                    <h2 className="d-md-flex d-none font-weight-light">
                        Redes Sociales
                    </h2>
                    <div className="d-md-flex d-none my-4">
                        {socials.map(({ imgs, link, name }, i) => (
                            <div key={i}>
                                <a href={link} target="_blank" rel="noopener noreferrer">
                                    <img src={imgs} alt={name} className="img-fluid ml-md-1 ml-0" />
                                </a>
                            </div>
                        ))}
                    </div>
                    <h5 className="d-md-flex my-4 d-none text-pink"> hola@designweekpuebla.com</h5>
                    <h5 className=" d-md-flex d-none">
                        Av. Venustiano Carranza 205, <br />
                        San Baltazar Campeche, 72550 <br />
                        Puebla, Pue. México
                    </h5>
                </Col>
                <Col md={3} sm={12} className="d-md-flex d-none flex-column ">
                    <div className="d-flex justify-content-center">
                        <img src={logo} alt="logo" className="footer_logo img-fluid" />
                    </div>
                </Col>
                <Col md={1} sm={0}></Col>
            </Row>
            <Row className="d-flex justify-content-center text-center mt-5 ">
                <a href="https://www.n12.mx" target="_blank" rel="noopener noreferrer"><p className="font-weight-light">Desarrollado por N12 Estudio®</p></a>
            </Row>
        </Container>
    )
}

export default Footer