import React from "react";
import { Container, Row, Col } from "reactstrap";
import Layout from "../../Layout";
import Violence from "./exhibits/Violence";
function GenderViolence() {
    return (
        <>
        <Layout>
            <Container className="bg-violencia" fluid></Container>
            <Container fluid className="my-3">
                <Row className="my-md-5 my-3">
                    <Col md={1}></Col>
                    <Col>
                        <h6 className="mt-4 font-weight-light text-dark ">
                                30 alertas contra la violencia de género
                        </h6>
                    </Col>
                </Row>
                <Row>
                    <Col md={1}></Col>
                    <Col>
                        <h5>
                                El proyecto “30 alertas contra la violencia de género” consiste en una colección de 30 carteles que hacen referencia gráficamente a los niveles del violentómetro propuestos desde el 2009 por la Dra. Martha Alicia Tronco Rosas del Instituto Politécnico Nacional en conjunto con la Unidad Politécnica de Gestión con Perspectiva de Género. (El Violentómetro está registrado ante el Instituto Nacional del Derecho de Autor con los Certificados de Registro Número: 03-2009- 120211370900-01, 03-2013-090510414900-01 y 03-2021-062811410900-01) 
                                <br /><br />
                                En esta colección participan 15 diseñadores y 15 diseñadoras que ejercen la docencia en distintas universidades (Universidad Autónoma de San Luis Potosí, Universidad Nacional Autónoma de México, Universidad Jesuita de Guadalajara, Universidad Veracruzana, Universidad Anáhuac Xalapa, Universidad Anáhuac Puebla, Benemérita Universidad Autónoma de Puebla y Universidad Iberoamericana Puebla) junto con profesionistas independientes con gran trayectoria en el diseño de cartel social.
                        </h5>
                    </Col>
                    <Col md={1}></Col>
                </Row>
            </Container>
            <hr
                style={{
                    width: "100%",
                    background: "#1d1d1b",
                    marginTop: 0,
                    marginLeft: 0
                }}
            />
            <Container fluid className="d-flex justify-items-center">
                <Row className="justify-content-center">
                    <Col md={10} sm={12}>
                        <Violence/>
                    </Col>
                </Row>
            </Container>
        </Layout>
        </>
    );
}

export default GenderViolence;
