import React from 'react';
import { Layout } from '../components'
import { Letrados } from "../components/sections/program2022"

function Objects(){
    return(
        <Layout>
            <Letrados/>
        </Layout>
    )
}

export default Objects;
