import React from 'react';
import { Layout } from '../components'
import { UsThird } from "../components/sections/us"
import "../style/home.css"

function ThirdEditionUs(){
    return(
        <Layout>
            <UsThird/>
        </Layout>
    )
}

export default ThirdEditionUs;
