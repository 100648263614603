import React from 'react';
import { Container, Row, Col } from 'reactstrap'
import pertenencia from "../../../assets/imgs/landing2022/home/pertenencia.png"
import exposiciones from "../../../assets/imgs/landing2022/home/exposiciones.png"
import foodesign from "../../../assets/imgs/landing2022/home/foodesign.png"
import territorio from "../../../assets/imgs/landing2022/home/territorio.png"
import barroquito from "../../../assets/imgs/landing2022/home/barroquito.png"
import objetos from "../../../assets/imgs/landing2022/home/letrados.jpg"
import "../../../style/home.css"

const info = [
    {
        imgs: pertenencia,
        title: "Quinta Edición",
        text: "Pertenecer",
        link: "pertenencia"
    },
    {
        imgs: objetos,
        title: "Eventos",
        text: "Eventos",
        link: "eventos"
    },
    {
        imgs: exposiciones,
        title: "Exposiciones",
        text: "Muestras Virtuales",
        link: "exposiciones"
    },
    {
        imgs: foodesign,
        title: "Ciudad Invitada",
        text: "Durango",
        link: "ciudad-invitada"
    },
    {
        imgs: barroquito,
        title: "Art Toy",
        text: "Barroquito",
        link: "art-toy"
    },
]
function Landing() {
    return (
        <>
            <Container className="bg_home d-flex flex-column justify-content-center" fluid>
            </Container>
            <Container fluid>
                <Row md={2} className="my-5 d-flex justify-content-center mt-md-5 px-5">
                    {info.map(({ title, text, imgs, link }, i) => (
                        <Col md={4} sm={12} key={i}>
                            <a href={link}>
                                <img src={imgs} alt={title} className="mt-md-5 img-fluid custom-landing-img" />
                            </a>
                            <div className="px-1">
                                <h5 className="font-weight-light mt-3 ">
                                    {title}
                                </h5>
                                <h2 className="font-weight-light">
                                    {text}
                                </h2>
                            </div>
                        </Col>
                    ))}
                    <Col md={1}></Col>
                </Row>
            </Container>
        </>
    )
}

export default Landing