import React from 'react';
import { Layout } from '../components'
import { Us, Team, Organizers } from "../components/sections/home"
import { Enlace } from "../components/sections/landingcontent"
import "../style/home.css"

function WeAre(){
    return(
        <Layout>
            <Us/>
            <div style={{ borderTop: "8px solid #e4e1e6 ",width:"100vw", marginLeft:0}}/>
            <Team/>
            <div style={{ borderTop: "8px solid #e4e1e6 ", width: "100vw", marginLeft: 0 }} />
            <Enlace />
            <div style={{ borderTop: "8px solid #e4e1e6 ",width:"100vw", marginLeft:0}}/>
            <Organizers/>
            
        </Layout>
    )
}

export default WeAre;
