import React from 'react';
import { Layout } from '../components'
import { OpenTypes } from "../components/sections/program2022/"
import "../style/home.css"

function TallerArtesanales() {
    return (
        <Layout>
            <OpenTypes />
        </Layout>
    )
}

export default TallerArtesanales;
