import React from 'react';
import { Layout } from '../../../components'
import { Landing, Organizers, Allies } from "./"
import "../../../style/home.css"

function Durango() {
    return (
        <Layout>
            <Landing />
            <div style={{ borderTop: "8px solid #e4e1e6 ", width: "100%", marginLeft: 0 }} />
            <Organizers />
            <div style={{ borderTop: "8px solid #e4e1e6 ", width: "100%", marginLeft: 0 }} />
            <Allies />
        </Layout>
    )
}

export default Durango;
