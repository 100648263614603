import React from 'react';
import { Container, Row, Col } from 'reactstrap'
import nov_05 from "../../../../assets/imgs/landing2022/foodesign/05_nov.jpg"
import nov_06 from "../../../../assets/imgs/landing2022/foodesign/06_nov.jpg"
import nov_08 from "../../../../assets/imgs/landing2022/foodesign/08_nov.jpg"
import nov_09 from "../../../../assets/imgs/landing2022/foodesign/09_nov.jpg"
import nov_10 from "../../../../assets/imgs/landing2022/foodesign/10_nov.jpg"

import "../../../../style/home.css"
const food_info = [
    {
        imgs: nov_05,
        title: "Family Meal",
        date: "Sábado 5 noviembre 2022 / 14:00 hrs.",
        text: "Casona María",
        place: "Calle 3 Oriente 1414, Barrio de Analco, Puebla",

    },
    {
        imgs: nov_06,
        title: "Family Meal",
        date: "Domingo 6 noviembre 2022 / 14:00 hrs.",
        text: "Casona María / Vica “Cocina de un Valle” ",
        place: "3 Ote. 1414, Barrio de Analco, Puebla, 2 Ote. 7, Centro histórico de Atlixco, Atlixco, Puebla",
    },
    {
        imgs: nov_08,
        title: "Family Meal",
        date: "Miércoles 8 noviembre 2022 / 14:30 hrs.",
        text: "Vica “Cocina de un Valle”",
        place: "2 Oriente 7, Centro histórico de Atlixco, Atlixco, Puebla.",
    },
    {
        imgs: nov_09,
        title: "Family Meal",
        date: "Jueves 9 noviembre 2022 / 14:30 hrs.",
        text: "Casona María",
        place: "Calle 3 Oriente 1414, Barrio de Analco, Puebla",
    },
    {
        imgs: nov_10,
        title: "Family Meal",
        date: "Viernes 10 noviembre 2022 / 14:30 hrs.",
        text: "Casona María",
        place: "Calle 3 Oriente 1414, Barrio de Analco, Puebla",
    }
   
]
function FoodDesign() {
    return (
        <Container className="d-flex flex-column justify-content-center" fluid>
            <Row className="mt-5">
                <Col>
                    <h1 className="ml-3 ml-md-0 font-weight-bold text-dark ">
                        Food Design
                    </h1>
                </Col>
            </Row>
            <Row md={4} className="my-5 d-md-flex">
                {food_info.map(({ title, text, imgs, date, place }, i) => (
                        <Col key={i} md={4} sm={12}>
                            <img src={imgs} alt={title} className="img-fluid worksimg" />
                        <h3 className="font-weight-light">{title}</h3>
                        <h4 className="font-weight-light">{date}</h4>
                        <h4 className="font-weight-light">{text}</h4>
                        <p className="font-weight-light">{place}</p>
                        </Col>
                ))}
            </Row>
        </Container >
    )
}

export default FoodDesign