import React from 'react';
import { Container, Row, Col } from 'reactstrap'
import "../../../../style/home.css"
import barroquito from "../../../../assets/imgs/barroquito/barroquitolanding.png"
function ArtToy() {
    return (
        <>
            <Container className="bg-arttoy d-flex flex-column justify-content-center" fluid>
            </Container>
            <Container className="d-flex flex-column justify-content-center" fluid>
                <Row className="mt-5">
                    <Col md={1}></Col>
                    <Col >
                        <h6 className="my-md-4 my-0 font-weight-light text-dark ">
                            Art Toy - Barroquito
                        </h6>
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col md={1}></Col>
                    <Col>
                        <h5 className="mt-5" >
                            Puebla como territorio es delineada, planeada y construida estratégicamente bajo el arquetipo de Ciudad Ideal por Fray Toribio de Benavente, Motolinía para los originarios, en 1531, con la intención de ser un Espacio Productivo y segundo importante después de la Nueva España, un lugar de descanso y paso.<br /><br />

                            Hacia el siglo XVII por las variantes de los procesos históricos se engendra un estilo artístico -paradigma de la época-, un estilo exagerado y claro, un modelado de superficies que, a la manipulación de masas y volúmenes, materiales y acabados, decoración elaborada y brillante: un distintivo Barroco Novohispano. Un barroco que se mezcló gustosamente con lo indígena, lo europeo y lo africano; una tendencia cultural que obedecía a la abundancia, a una tradición cultural e imaginaria única en el mundo.<br /><br />

                            Retomando ese trozo en la Historia de Puebla, Barroquito en el siglo XXI, es el imaginario y la vida diaria de los poblanos; la saturación de elementos, la naturaleza y vegetación de las formas, la ornamentación religiosa resplandeciente, la teatralidad de los espacios de edificios cívicos, los juegos visuales al caminar Puebla; un Blank Toy para artesanos, artistas plásticos, arquitectos, diseñadores, investigadores e historiadores; que en la intervención por apropiación (hacerlo suyo para darle un carácter a partir de su oficio- profesión, de su imaginario-vida y de su interpretación), vemos reflejada a Puebla en un Design Week que muestra, aporta y produce.<br /><br />

                            Barroquito, diseñado por Mike Wynter, refleja y es la sensibilidad de una ciudad que piensa, produce y aporta a nuestra vida, al país y al mundo, un Blank Toy trozo del imaginario individual y colectivo de nuestra historia, identidad y cultura en la globalización.<br /><br />

                        </h5>
                    </Col>
                    <Col md={1}></Col>
                </Row>
                <Row className="d-flex justify-content-center mb-5 ">
                    <img src={barroquito} alt="barroquito background" className="img-fluid img_barroquito" />
                </Row>
                <Row className="d-flex justify-content-center align-items-center text-center mb-5 ">
                    <Col md={3} sm={12}>
                        <a href="/cuarta-edicion">
                            <h5 className="font-weight-bold text-pink"><u> Cuarta Edición</u></h5>
                        </a>
                    </Col>
                    <Col md={3} sm={12}>
                        <a href="/tercera-edicion">
                            <h5 className="font-weight-bold text-pink"><u> Tercera Edición</u></h5>
                        </a>
                    </Col>
                    <Col md={3} sm={12}>
                        <a href="/segunda-edicion">
                            <h5 className="my-md-0 my-5 font-weight-bold text-pink"><u>Segunda Edición</u></h5>
                        </a>
                    </Col>
                    <Col md={3} sm={12}>
                        <a href="/primera-edicion">
                            <h5 className="font-weight-bold text-pink"><u>Primera Edición</u></h5>
                        </a>
                    </Col>
                </Row>

            </Container>
        </>
    )
}

export default ArtToy