import React, { useState, useEffect, useCallback  } from 'react';
import {
Container,
Row,
} from "reactstrap";
import { motion } from "framer-motion";


import mike from "../../assets/imgs/allies/mike.png"
import n12 from "../../assets/imgs/allies/n12.png"
import ied from "../../assets/imgs/allies/ied.png"
import valenza from "../../assets/imgs/allies/valenza.png"
import origen from "../../assets/imgs/allies/origen.png"
import xohitla from "../../assets/imgs/allies/xohitla.png"

import catalina from "../../assets/imgs/allies/secondary/catalina.png"
import elisava from "../../assets/imgs/allies/secondary/elisava.png"
import ibero from "../../assets/imgs/allies/secondary/ibero.png"
import maniquies from "../../assets/imgs/allies/secondary/maniquies.png"
import muitec from "../../assets/imgs/allies/secondary/muitec.png"
import multicopias from "../../assets/imgs/allies/secondary/multicopias.png"
import sanfranciscomarket from "../../assets/imgs/allies/secondary/sanfranciscomarket.png"
import trozmer from "../../assets/imgs/allies/secondary/trozmer.png"
import upaep from "../../assets/imgs/allies/secondary/upaep.png"


const alliesroutes = [
    {
        img: mike,
        src: "https://www.instagram.com/mike_wynter/"
    },
    {
        img: ied,
        src: "https://www.ied.edu "
    },
    {
        img: valenza,
        src: " https://www.freelancervalenza.com"
    },
    {
        img: origen,
        src: " https://www.facebook.com/hostel.origen.9 "
    },
    {
        img: xohitla,
        src: "https://www.facebook.com/lapalabraflorida  "
    },
    {
        img: catalina,
        src: "https://www.facebook.com/catalina.bistropuebla/ "
    },
    {
        img: elisava,
        src: "https://www.elisava.net"
    },
    {
        img: ibero,
        src: "https://www.iberopuebla.mx"
    },
    {
        img: maniquies,
        src: "https://www.facebook.com/MANIQUIESANDDISPLAY/"
    },
    {
        img: muitec,
        src: "https://elmui.online"
    },
    {
        img: multicopias,
        src: "https://www.multicopias.com.mx"
    },
    {
        img: sanfranciscomarket,
        src: "https://www.instagram.com/sanfranciscomarketfest/"
    },
    {
        img: trozmer,
        src: "https://trozmer.edu.mx"
    },
    {
        img: upaep,
        src: "https://upaep.mx"
    },
];


const AlliesCarousel = () => {

    const [indexPage, setIsIndexPage] = useState(0);
    const totalPages = alliesroutes.length;

    const incPag = useCallback(() => {
        const nextIndex = indexPage + 1;
        if (nextIndex < totalPages) {
        setIsIndexPage(nextIndex);
        } else {
        setIsIndexPage(0);
        }
    }, [indexPage, totalPages]);

    useEffect(() => {
        const timer = setTimeout(() => {
        incPag();
        }, 1500);
        return () => clearTimeout(timer);
    }, [indexPage, incPag]);


    return (
        <Container   fluid>
            <Row  >

                    <motion.div
                    key={indexPage}
                    initial={{ x: 10, opacity: 0.5 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -10, opacity: 0.5 }}>
                        <div className="d-flex flex-row">
                        <a href={alliesroutes[indexPage].src} target="_blank" rel="noopener noreferrer">
                            <img src={alliesroutes[indexPage].img} alt="img src" className="img-fluid"/>
                        </a>

                        <a href="https://www.n12.mx" target="_blank" rel="noopener noreferrer">
                            <img src={n12} alt="img src" className="img-fluid"/>
                        </a>    
                        </div>

                    </motion.div>

            </Row>
        </Container>
    );
};

export default AlliesCarousel;