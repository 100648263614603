import React from 'react';
import { Container, Row, Col } from 'reactstrap'
import "../../../style/home.css"

import img1 from "../../../assets/imgs/barroquito/gifs/1.gif"
import img2 from "../../../assets/imgs/barroquito/gifs/2.gif"
import img3 from "../../../assets/imgs/barroquito/gifs/3.gif"
import img4 from "../../../assets/imgs/barroquito/gifs/4.gif"
import img5 from "../../../assets/imgs/barroquito/gifs/5.gif"
import img6 from "../../../assets/imgs/barroquito/gifs/6.gif"
import img7 from "../../../assets/imgs/barroquito/gifs/7.gif"
import img8 from "../../../assets/imgs/barroquito/gifs/8.gif"
import img9 from "../../../assets/imgs/barroquito/gifs/9.gif"
import img10 from "../../../assets/imgs/barroquito/gifs/10.gif"
import img11 from "../../../assets/imgs/barroquito/gifs/11.gif"
import img12 from "../../../assets/imgs/barroquito/gifs/12.gif"
import img13 from "../../../assets/imgs/barroquito/gifs/13.gif"
import img14 from "../../../assets/imgs/barroquito/gifs/14.gif"
import img15 from "../../../assets/imgs/barroquito/gifs/15.gif"
import img16 from "../../../assets/imgs/barroquito/gifs/16.gif"
import img17 from "../../../assets/imgs/barroquito/gifs/17.gif"
import img18 from "../../../assets/imgs/barroquito/gifs/18.gif"
import img19 from "../../../assets/imgs/barroquito/gifs/19.gif"
import img20 from "../../../assets/imgs/barroquito/gifs/20.gif"
import img21 from "../../../assets/imgs/barroquito/gifs/21.gif"
import img22 from "../../../assets/imgs/barroquito/gifs/22.gif"
import img23 from "../../../assets/imgs/barroquito/gifs/23.gif"

const thirdcont = [
    {
        numberpiece: "Pieza 1 de 29",
        artist: "After Five Studio: Kristian Espinal",
        src: img1,
        text: `"Mérolico melancólico"`,
        socials: "https://www.instagram.com/afterfivestudio/"
    },
    {
        numberpiece: "Pieza 2 de 29",
        artist: "Eduardo Picazo",
        text: `"Wuicholito"`,
        src: img2,
        socials: "https://www.instagram.com/eduardo.picazo/"
    },
    {
        numberpiece: "Pieza 3 de 29",
        artist: "Emil Murín",
        text: `"Barroquito de la vida"`,
        src: img3,
        socials: "https://www.instagram.com/emilmurin.art/"
    },
    {
        numberpiece: "Pieza 4 de 29",
        artist: "Fly Boy",
        text: `"Resurrección"`,
        src: img4,
        socials: "https://www.instagram.com/Fly_boy_co/"
    },
    {
        numberpiece: "Pieza 5 de 29",
        artist: "Guisanro",
        text: `"Blue Talavero"`,
        src: img5,
        socials: "https://www.instagram.com/le_guisanro/"
    },
    {
        numberpiece: "Pieza 6 de 29",
        artist: "Jimena Peluche",
        text: `"Vendedor de ilusiones"`,
        src: img6,
        socials: "https://www.instagram.com/jimenapeluche/"
    },
    {
        numberpiece: "Pieza 7 de 29",
        artist: "John Mirón",
        text: `"Tecuán"`,
        src: img7,
        socials: "https://www.instagram.com/john_miron/"
    },
    {
        numberpiece: "Pieza 8 de 29",
        artist: "Kuii",
        text: `"Renaciendo en vida"`,
        src: img8,
        socials: "https://www.instagram.com/kuii.change/"
    },
    {
        numberpiece: "Pieza 9 de 29",
        artist: "Luis Reyes",
        text: `"Torito"`,
        src: img9,
        socials: "https://www.facebook.com/luis.reyezz"
    },
    {
        numberpiece: "Pieza 10 de 29",
        artist: "Marco Antonio Hernández",
        text: `"Mi Puebla"`,
        src: img10,
        socials: "https://www.instagram.com/marc_sesiones_dibujo/"
    },
    {
        numberpiece: "Pieza 11 de 29",
        artist: "Marian Cheshire",
        text: `"Ofrenda a Barroquito"`,
        src: img11,
        socials: "https://www.instagram.com/marian_cheshire/"
    },
    {
        numberpiece: "Pieza 12 de 29",
        artist: "Marina Lua",
        text: `"Reverdecer"`,
        src: img12,
        socials: "https://www.instagram.com/marinaluaart/"
    },
    {
        numberpiece: "Pieza 13 de 29",
        artist: "Pascual García",
        text: `"Mandala Barroquito"`,
        src: img13,
        socials: "https://www.instagram.com/patepascual/"
    },
    {
        numberpiece: "Pieza 14 de 29",
        artist: "Patatús",
        text: `"Diablito Magenta Patatús"`,
        src: img14,
        socials: "https://www.instagram.com/patatusmx/"
    },
    {
        numberpiece: "Pieza 15 de 29",
        artist: "Rhekot",
        text: `"Panda Type <El Protector Tipográfico>"`,
        src: img15,
        socials: "https://www.instagram.com/rich.art_97/"
    },
    {
        numberpiece: "Pieza 16 de 29",
        artist: "Sarai Loyola",
        text: `"Tláloc del Zapotal"`,
        src: img16,
        socials: "https://www.instagram.com/sarai.loyola/"
    },
    {
        numberpiece: "Pieza 17 de 29",
        artist: "Sergio Mena",
        text: `"Shin-Barroquito"`,
        src: img17,
        socials: "https://www.instagram.com/sergiomena_/"
    },
    {
        numberpiece: "Pieza 18 de 29",
        artist: "Silver Sun",
        text: `"Primordial"`,
        src: img18,
        socials: "https://www.instagram.com/silversundg/"
    },
    {
        numberpiece: "Pieza 19 de 29",
        artist: "Spika",
        text: `"La ventana del alma"`,
        src: img19,
        socials: "https://www.instagram.com/spika.art/"
    },
    {
        numberpiece: "Pieza 20 de 29",
        artist: "ViCus",
        text: `"Niño monarca"`,
        src: img20,
        socials: "https://www.instagram.com/ViciusMtz/"
    },
    {
        numberpiece: "Pieza 21 de 29",
        artist: "Zotzz",
        text: `"Espíritu Tecuán"`,
        src: img21,
        socials: "https://www.instagram.com/zotzz_/"
    },
    {
        numberpiece: "Pieza 22 de 29",
        artist: "Xmucane Ollin",
        text: `"Guerrero Jaguar policromado"`,
        src: img22,
        socials: "https://www.instagram.com/xmucaneollin/"
    },
    {
        numberpiece: "Pieza 23 de 29",
        artist: "Xmucane Ollin",
        text: `"Tlachihualtepetl Barroco"`,
        src: img23,
        socials: "https://www.instagram.com/xmucaneollin/"
    },

]
function FourthEdition() {
    return (
        <Container className="d-flex flex-column justify-content-center" fluid>
            <Row className="my-5">
                <Col md={1}></Col>
                <Col >
                    <h2 className=" font-weight-bold text-gray">
                        Art Toy - Barroquito
                    </h2>

                    <h6 className=" mt-4 font-weight-bold text-dark ">
                        Tercera Edición
                    </h6>
                </Col>
            </Row>
            <Row md={3} className="d-flex justify-content-center align-items-center">
                {thirdcont.map(({ numberpiece, artist, src, socials, text }, i) => (
                    <>
                        <Col sm={4} className="d-flex flex-column align-items-center my-4 px-md-0 ">
                            <img src={src} alt="barroquito" className="img-fluid barroquito_img text-center " />
                            <div className="d-flex flex-column justify-content-center align-items-left text-left">
                                <h5 className="text-center ">
                                    {numberpiece}
                                </h5>

                                <h3 className="text-center font-weight-bold">
                                    {artist}
                                </h3>
                                <h4 className="text-center font-weight-bold">
                                    {text}
                                </h4>
                                <a href={socials} target="_blank" rel="noopener noreferrer">
                                    <h5 className="d-md-flex justify-content-center text-center text-pink font-weight-bold">
                                        <u>Ver más sobre el artista</u>
                                    </h5>
                                </a>
                            </div>
                        </Col>
                    </>
                ))}
            </Row>
        </Container>
    )
}

export default FourthEdition