import React from "react";
import { Container, Row, Col } from "reactstrap";
import Expositions from "./Expositions";
import Conferences from "./Conferences";
import Workshops from "./Workshops";
import Etnographic from "./Etnographic";
import FoodDesign from "./FoodDesign";
import DesignNight from "./DesignNight";
import "../../../../style/home.css"

function Belongings() {
    return (
        <>
            <Container className="bg-ciudad " fluid>
            </Container>
            <Container className="my-5" fluid>
                <Row className="mt-5">
                    <Col md={1}></Col>
                    <Col >
                        <h6>
                            Pertenencia
                        </h6>
                    </Col>
                </Row>
                <Row>
                    <Col md={1}></Col>
                    <Col >
                        <h4 className="my-md-3 my-3">
                            En la dimensión del reconocimiento el “sentido de pertenecer o sentimiento de pertenencia” es una
                            conciencia cultural de los individuos y del proceso de formación de las identidades, de las diferencias
                            culturales (de regiones, comunidades e individuos) que conforman la heterogeneidad cultural de
                            cualquier territorio, región o nación. Anthony P. Cohen Belonging: The Experience of Culture.
                            <br /><br />
                            Y de la Pertenencia en la dimensión del Patrimonio, es el acto del sentir antes del acto de tener,
                            condición humana que define límites en la Ciudadanía. La pertenencia no es un lujo: es un proceso
                            de ciudadanía cultural dentro de una sociedad multicultural.
                        </h4>
                    </Col>
                    <Col md={1}></Col>
                </Row>
            </Container>
            <div style={{ borderTop: "8px solid #e4e1e6 ", width: "100%", marginLeft: 0 }} />
        </>
    )
}

export default Belongings