import React from "react";
import { Container, Row, Col } from "reactstrap";
import Layout from "../../Layout";
import Aswer from "./exhibits/Aswer";
function Raices() {
    return (
        <>
            <Layout>
                <Container className="bg-aswer" fluid></Container>
                <Container fluid className="my-3">
                    <Row className="my-md-5 my-3">
                        <Col md={1}></Col>
                        <Col>
                            <h6 className="mt-4 font-weight-light text-dark ">
                                Raices 
                                <br />
                                Aswer García
                            </h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={1}></Col>
                        <Col>
                            <h5>
                                "Aswer García, ilustrador y Director de Arte Mexicano. Su obra retoma varios elementos y cosmovisión Mesoamericana, en específico del México Antiguo del Valle de la Ciudad de México.
                                <br />

                                En su obra involucra mensajes y frases jugando con metáforas que aborden temas del corazón, amor propio, relaciones y sobre las emociones humanas que se puedan sentir.
                                <br />
                                Como resultado se ha podido llegar a una difusión importante en varios medios importantes de diseño y arte en México, así como trabajar con múltiples marcas.
                                <br />
                                Esta exposición nos lleva por un recorrido en la trayectoria de Aswer.
                                <br />
                                “Aswer García se convirtió en mi diario y desahogo personal, en el cual involucro todas mis energías lo cual me ha permitido general múltiples proyectos”. (Anwer García)"
                            </h5>
                        </Col>
                        <Col md={1}></Col>
                    </Row>
                </Container>
                <hr
                    style={{
                        width: "100%",
                        background: "#1d1d1b",
                        marginTop: 0,
                        marginLeft: 0
                    }}
                />
                <Container fluid className="d-flex justify-items-center">
                    <Row className="justify-content-center">
                        <Col md={10} sm={12}>
                            <Aswer/>
                        </Col>
                    </Row>
                </Container>
            </Layout>
        </>
    );
}

export default Raices;
