import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import "../../../style/home.css"

import valenza from "../../../assets/imgs/allies/valenza.png"
import miscelanea from "../../../assets/imgs/organizers/miscelanea.png"
import dix from "../../../assets/imgs/organizers/dix.png"
import liz from "../../../assets/imgs/organizers/liz.png"

const organizers = [
    {
        imgs: miscelanea,
        name: "Miscelánea",
        link: "http://lamiscelanea.org.mx/"
    },
    {
        imgs: dix,
        name: "DIX",
        link: ""
    },
    {
        imgs: valenza,
        name: "Valenza",
        link: "https://valenza-1.ueniweb.com/"
    },
    {
        imgs: liz,
        name: "liz",
        link: ""
    },
    {
        imgs: liz,
        name: "Gobierno del Estado de Puebla",
        link: ""
    },
    {
        imgs: liz,
        name: "Secretaria de Cultura del Estado de Puebla",
        link: ""
    },
]

function Organizers() {
    return (
        <>
            <Container className="d-md-flex d-none flex-column align-items-center justify-content-center" fluid>
                <Row className="align-items-center justify-content-start">
                    <Col md={1} sm={12}></Col>
                    <Col md={2} sm={12}>
                        <h2 className="font-weight-light"> Organizadores.</h2>
                    </Col>
                    {organizers.map(({ imgs, name, link }, i) => (
                        <Col md={1} sm={12}>
                            <a href={link} target="_blank" rel="noopener noreferrer">
                                <img src={imgs} alt={name} className="img-fluid" />
                            </a>
                        </Col>
                    ))}
                </Row>
            </Container>
        </>
    )
}

export default Organizers 