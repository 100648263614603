import React from 'react';
import { Container, Row, Col } from 'reactstrap'
import "../../../../style/home.css"

function Study() {
    return (
        <>
            <Container className="bg-territory" fluid>

            </Container>
            <Container className="d-flex flex-column justify-content-center px-md-0 px-4" fluid>
                <Row className="my-md-5 my-3">
                    <Col md={1}></Col>
                    <Col >
                        <h6 className=" mt-4 font-weight-light text-dark ">
                            Estudio de territorio transversal
                        </h6>
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col md={1}></Col>
                    <Col >
                        <h4  >
                            Nos hemos planteado el Territorio Transversal como un agente de estudio, producción y trasformación; un ente activo, dinámico, autónomo y comunitario. El TT como factor del desarrollo económico, cultural y de innovación social, cuatro rutas con cuatro indicadores y cuatro momentos para estar con el originario. Ubicamos el territorio, lo analiza- mos a partir de cartografías sociales, estudios etnográficos y netnográficos, genera- mos diálogos de confianza, compartimos el proceso y somos parte de la comunidad, así como nos lo dice Manuel Sainos –Poeta Totonaco- <br /><br />

                            <h1 className="font-weight-light ">
                                “Ustedes entraron a nuestras comunidades con respeto, mostrándonos y abrazando nuestras vidas. Tal cual somos.”<br /><br />
                            </h1>
                            El Territorio Transversal se teje en rutas basadas en memoria, saberes y cosmovisión; puntos cardinales, geografía y tierras: la Sierra Nevada, Sierra Mixteca, Sierra Nororiental y la Angelópolis. Cuatro indicadores de análisis: Tradición e Historia, Paisaje Material e Inmaterial, Gastronomía y Artesanía; pensamos, pertenecemos, producimos y nos inspiramos en Productos con Identidad: <span className="font-weight-bold">objetos letrados con diseño.</span>

                            90 cápsulas etnográficas, distribuidas en 4 rutas poblanas, conformadas por 20 comunidades:
                            Calpan, Cholula, Huejotzingo, Tochimilco, Atlixco, Cuicatlán, Tehuacán, Acatlán de Osorio, Huixcolotla, Tecali, Tepeaca, Pahuatlán, Zacatlán, Olintla, Huehuetla, Hueytlalpan, Cuetzalan, Tetela de
                            Ocampo, Izúcar de Matamoros y Chignahuapan.<br /><br />
                            Disponibles en facebook: https://www.facebook.com/designweekpuebla
                        </h4>
                    </Col>
                    <Col md={1}></Col>
                </Row>
                
            </Container>
        </>
    )
}

export default Study