import React from 'react';
import { Layout } from '../components'
import { VirtualShowcase } from "../components/sections/program2022"
import "../style/home.css"

function ExpoVirtual() {
    return (
        <Layout>
            <VirtualShowcase />
        </Layout>
    )
}

export default ExpoVirtual;
