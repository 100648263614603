import React from 'react';
import { Layout } from '../components'
import { Loteria } from "../components/sections/program2022"
import "../style/home.css"

function MexicanLottery(){
    return(
        <Layout>
            <Loteria/>
        </Layout>
    )
}

export default MexicanLottery;
