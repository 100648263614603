import React from 'react';
import { Layout } from '../components'
import { FourthEdition } from "../components/sections/editions"
import "../style/home.css"

function Fourth() {
    return (
        <Layout>
            <FourthEdition />
        </Layout>
    )
}

export default Fourth;
