import React from 'react';
import { Container, Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap'
import "../style/barroquito.css"

function ContactPage(){
return(
        <Container className="d-flex flex-column justify-content-center my-5" fluid>
            <Row className="my-5"> 
                <Col md={1}></Col>
                <Col>
                    <h2 className=" font-weight-bold text-gray">
                        Design Week Puebla
                    </h2>
                    <h6 className=" mt-4 font-weight-bold text-dark ">
                        Contacto
                    </h6>
                </Col>
            </Row>
            <Row>
                <Col md={3}></Col>
                <Col md={6}>
                    <Form className="px-md-0 px-5">
                        <FormGroup className="d-flex flex-row ">
                            <Label for="Asunto"><h5 className=" font-weight-bold "> Asunto:</h5> </Label>
                            <div className="d-flex flex-row ml-auto custom_long" >
                                <Input type="select" name="select" id="asunto">
                                <option>Art-Toy Barroquito</option>
                                <option>Colaboraciones y Patrocinios</option>
                                <option>Información general</option>
                                <option>Otro</option>
                                </Input>
                            </div>
                        </FormGroup>
                        <FormGroup className="d-flex flex-row ">
                            <Label for="name"><h5 className="font-weight-bold"> Nombre Completo: </h5> </Label>
                                <div className="d-flex flex-row ml-auto custom_long" >
                                <Input type="name" name="name" id="name" placeholder="Nombre y apellido" />
                                </div>
                        </FormGroup>
                        <FormGroup className="d-flex flex-row align-items-center">
                            <Label for="Email"><h5 className=" font-weight-bold">Email: </h5></Label>
                            <div className="d-flex flex-row ml-auto custom_long" >
                            <Input type="email" name="email" id="Email" placeholder="Correo Electrónico"/>
                            </div>
                        </FormGroup>

                        <FormGroup className="d-flex flex-row align-items-center justify-content-center"> 
                            <Label for="message"><h5 className=" font-weight-bold">Mensaje: </h5></Label>
                            <div className="d-flex flex-row ml-auto custom_long" >
                            <Input type="message" name="message" id="message" placeholder="Mensaje" />
                            </div>
                        </FormGroup>
                        <div className="d-flex flex-row justify-content-end">
                        <Button color="primary" size="lg">Enviar</Button>
                        </div>
                    </Form>
                </Col>
                <Col md={3}></Col>
            </Row>

            
        </Container>
    )
}

export default ContactPage