import React from 'react';
import { Container, Row, Col } from 'reactstrap'
import "../../../style/home.css"
const team_info = [
    {
        teammate: "Horacio Cinto",
        position: "Director General",
        email: "horacio@designweekpuebla.com"
    },
    {
        teammate: "Cuauhtémoc Sánchez",
        position: "Relaciones institucionales",
        email: "cuauhtemoc@designweekpuebla.com"
    },
    {
        teammate: "Lilian López",
        position: "Coordinadora operativa",
        email: "lilian.lopez@designweekpuebla.com"
    },
    {
        teammate: "Lizbeth Mozo",
        position: "Presidenta de consejo directivo",
        email: "lizbeth@designweekpuebla.com"
    },
    {
        teammate: "Mike Wynter",
        position: "Art Toy",
        email: ""
    },
    {
        teammate: "Lilian León",
        position: "Food Design",
        email: ""
    },
]
function Team() {
    return (
        <>
            <Container className="d-flex flex-column justify-content-center " fluid>
                <Row className="mt-5">
                    <Col md={1}></Col>
                    <Col>
                        <h6 className=" my-md-4 my-2 font-weight-light text-dark ">
                            El Equipo
                        </h6>
                    </Col>
                </Row>
                <Row className="my-md-5 my-3">
                    <Col md={1}></Col>
                    <Col >
                        <h5  >

                            Design Week Puebla está integrado por un equipo joven con experiencia, un grupo interdisciplinario de profesionales enfocado a cada una de las áreas del festival, haciendo que esta organización responda a las necesidades sociales, culturales y académicas del Estado de Puebla a través del diseño, las artes y la cultura.
                        </h5>
                    </Col>
                    <Col md={1}></Col>
                </Row>
                <Row md={4} className="px-md-0 px-5 d-flex flex-row justify-items-center text-center justify-content-center">
                    {team_info.map(({ teammate, position, email }, i) => (
                        <div key={i} className="mx-5 mb-5 d-flex">
                            <Col md={12} sm={12}>
                                <h4 className="font-weight-bold">
                                    {teammate}
                                </h4>
                                <h4>
                                    {position}
                                </h4>
                                <h5 className="text-pink">
                                    {email}
                                </h5>
                            </Col>
                        </div>
                    ))}
                </Row>
            </Container>
        </>
    )
}

export default Team