import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import "../../../style/home.css"

import mike from "../../../assets/imgs/allies/mike.png"
import n12 from "../../../assets/imgs/allies/n12.png"
import ied from "../../../assets/imgs/allies/ied.png"
import valenza from "../../../assets/imgs/allies/valenza.png"
import ibero from "../../../assets/imgs/allies/secondary/ibero.png"
import maniquies from "../../../assets/imgs/allies/secondary/maniquies.png"
import muitec from "../../../assets/imgs/allies/secondary/muitec.png"
import multicopias from "../../../assets/imgs/allies/secondary/multicopias.png"
import anahuac from "../../../assets/imgs/allies/secondary/anahuac.png"
import traspatio from "../../../assets/imgs/allies/secondary/traspatio.png"
import vica from "../../../assets/imgs/allies/secondary/vica.png"
import enadi from "../../../assets/imgs/allies/secondary/enadi.png"

import miscelanea from "../../../assets/imgs/organizers/miscelanea.png"
import dix from "../../../assets/imgs/organizers/dix.png"
import liz from "../../../assets/imgs/organizers/liz.png"

import maroma from "../../../assets/imgs/allies/secondary/maroma.png"
import ies from "../../../assets/imgs/allies/secondary/ies.png"
import icum from "../../../assets/imgs/allies/icum.png"
import bacuza from "../../../assets/imgs/allies/secondary/bacuza.jpg"

import casona from "../../../assets/imgs/allies/secondary/casona.png"
import tec from "../../../assets/imgs/allies/secondary/tec.png"
import ladrillos from "../../../assets/imgs/allies/secondary/ladrillos.png"
import mendrugo from "../../../assets/imgs/allies/secondary/mendrugo.png"

const alliesroutes = [
    {
        img: n12,
        src: "https://www.n12.mx"
    },
    {
        img: miscelanea,
        src: ""
    },
    {
        img: dix,
        src: ""
    },
    {
        img: liz,
        src: ""
    },
    {
        img: mike,
        src: "https://www.instagram.com/mike_wynter/"
    },
    {
        img: valenza,
        src: "https://www.freelancervalenza.com"
    },
    {
        img: ied,
        src: "https://www.ied.edu"
    },
    {
        img: ibero,
        src: "https://www.iberopuebla.mx"
    },
    {
        img: maniquies,
        src: "https://www.facebook.com/MANIQUIESANDDISPLAY/"
    },
    {
        img: muitec,
        src: "https://elmui.online"
    },
    {
        img: multicopias,
        src: "https://www.multicopias.com.mx"
    },
    {
        img: anahuac,
        src: "https://www.multicopias.com.mx"
    },
    {
        img: traspatio,
        src: "https://www.traspatio.com.mx"
    },
    {
        img: vica,
        src: "https://www.facebook.com/vicacocinadeunvalle"
    },
    {
        img: maroma,
        src: "https://lamaroma.com/"
    },
    {
        img: ies,
        src: "http://iesac.edu.mx"
    },
    {
        img: casona,
        src: "https://www.casonamaria.com"
    },
    {
        img: tec,
        src: "https://tec.mx/es/puebla"
    },
    {
        img: icum,
        src: "https://icum.edu.mx"
    },
    {
        img: ladrillos,
        src: "https://www.instagram.com/ladrillosdemexico/"
    },
    {
        img: mendrugo,
        src: "https://www.casadelmendrugo.com"
    },
    {
        img: enadi,
        src: "https://instagram.com/enadiimexico_oficial"
    },
    {
        img: bacuza,
        src: "https://www.facebook.com/casabacuuza"
    }

];

function AlliesPage(){
    return(
        <Container className="d-flex flex-column justify-content-center" fluid>
            <Row className="mt-5"> 
                <Col md={1} sm={12}></Col>
                <Col md={10} sm={12}>
                    <h2 className="mt-md-0 mt-5  font-weight-bold text-gray">
                        Design Week Puebla
                    </h2>

                    <h6 className="mt-3 mb-5  font-weight-bold text-dark ">
                        Aliados
                    </h6>
                </Col>
            </Row>
            <Row md={4} className="d-flex justify-content-center align-items-center text-center mb-5">
                
                {alliesroutes.map(({img, src}, i) =>(
                    <>
                        <div key={i}>
                            <a href={src} target="_blank" rel="noopener noreferrer">
                                <img src={img} alt="allied logo" className="img-fluid" />
                            </a>
                        </div>
                    </>
                ))}
            </Row>
            
            
        </Container>

        
    )
}

export default AlliesPage 