import React from "react";
import { Row, Col } from "reactstrap";
import loteria from "../../../../assets/imgs/landing2022/exposiciones/expo_loteria.png";
import barroquito from "../../../../assets/imgs/landing2022/exposiciones/expo_barroquito.jpg";
import mirada from "../../../../assets/imgs/landing2022/exposiciones/expo_mirada.jpg";
import objetos from "../../../../assets/imgs/landing2022/home/letrados.jpg";
import fachadas from "../../../../assets/imgs/landing2022/exposiciones/expo_100_fachadas.jpg";

import "../../../../style/home.css";

const expositions_info = [
  {
    imgs: loteria,
    title: "Lotería Poblana",
    text: "Elmer Sosa",
    link: "loteria",
    date: "Viernes 4 de noviembre de 2022 / 17:00 hrs.",
    street: "Calle 3 Oriente 1414, Barrio de Analco, Puebla",
    place: "Casona María"
  },
  {
    imgs: barroquito,
    title: "Barroquito 2022",
    text: "Exposición colectiva",
    link:  ``,
    date: "Miércoles 5 noviembre 2021, 19:00 hrs. Traspatio",
    street: "5 sur 705, Centro histórico de Puebla.",
    place: ""
  },
  {
    imgs: fachadas,
    title: "Cien fachadas, un millón de historias",
    text: "Mal de ojo",
    link: "fachadas",
    date: "Lunes 7 noviembre 2022 / 19:00 hrs.",
    street: "Online: en vivo Facebook",
    place: ""
  },
  {
    imgs: mirada,
    title: "Una mirada al interiorismo Latinoamericano",
    text: "Exposición colectiva",
    link: "interiorismo",
    date: "Miércoles 9 noviembre 2022 / 11:00 hrs. Innovaction Gym",
    street:
      "Tecnológico de Monterrey: Atlixcáyotl 5718, Reserva Territorial Atlixcáyotl, Puebla",
    place: ""
  },

  {
    imgs: objetos,
    title: "Objetos letrados con diseño II",
    text: "Exposición colectiva",
    link: "eventos",
    date: "Viernes 11 de noviembre de 2022 / 17:00 hrs. Casona María",
    street: "Calle 3 Oriente 1414, Barrio de Analco, Puebla",
    place: ""
  }
];
function Expositions() {
  return (
    <div className="d-flex flex-column my-md-5">
      <Row>
        <Col>
          <h1 className="font-weight-bold mt-3 text-dark ">Exposiciones</h1>
        </Col>
      </Row>
      <Row className="my-5 mt-md-5">
        {expositions_info.map(
          ({ title, text, imgs, link, date, street, place }, i) => (
            <Col key={i} md={4} sm={12} className="mt-2">
              <a href={link}>
                <img
                  src={imgs}
                  alt="placeholder"
                  className="img-fluid custom-landing-img"
                />
              </a>
              <div>
                <h2 className="font-weight-light">{title}</h2>
                <h3 className="font-weight-light">{text}</h3>
                <h3 className="font-weight-light">{place}</h3>
                <h5 style={{ width: "20vw" }} className="font-weight-light">
                  {street}
                </h5>
                <p>{date}</p>
              </div>
            </Col>
          )
        )}
      </Row>
    </div>
  );
}

export default Expositions;
