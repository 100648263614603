import React from 'react';
import { Layout } from '../components'
import { Belonging } from "../components/sections/program2022"

function Pertenencia() {
    return (
        <Layout>
            <Belonging/>
        </Layout>
    )
}

export default Pertenencia;
