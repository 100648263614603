import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "reactstrap";
import img01 from "../../../../assets/imgs/landing2023/exposiciones/raices/01.png";
import img02 from "../../../../assets/imgs/landing2023/exposiciones/raices/02.png";
import img03 from "../../../../assets/imgs/landing2023/exposiciones/raices/03.png";
import img04 from "../../../../assets/imgs/landing2023/exposiciones/raices/04.png";
import img05 from "../../../../assets/imgs/landing2023/exposiciones/raices/05.png";
import img06 from "../../../../assets/imgs/landing2023/exposiciones/raices/06.png";
import img07 from "../../../../assets/imgs/landing2023/exposiciones/raices/07.png";
import img08 from "../../../../assets/imgs/landing2023/exposiciones/raices/08.png";
import img09 from "../../../../assets/imgs/landing2023/exposiciones/raices/09.png";
import img10 from "../../../../assets/imgs/landing2023/exposiciones/raices/10.png";
import img11 from "../../../../assets/imgs/landing2023/exposiciones/raices/11.png";
import img12 from "../../../../assets/imgs/landing2023/exposiciones/raices/12.png";
import img13 from "../../../../assets/imgs/landing2023/exposiciones/raices/13.png";
import img14 from "../../../../assets/imgs/landing2023/exposiciones/raices/14.png";
import img15 from "../../../../assets/imgs/landing2023/exposiciones/raices/15.png";
import img16 from "../../../../assets/imgs/landing2023/exposiciones/raices/16.png";
import img17 from "../../../../assets/imgs/landing2023/exposiciones/raices/17.png";
import img18 from "../../../../assets/imgs/landing2023/exposiciones/raices/18.png";
import img19 from "../../../../assets/imgs/landing2023/exposiciones/raices/19.png";
import img20 from "../../../../assets/imgs/landing2023/exposiciones/raices/20.png";
import img21 from "../../../../assets/imgs/landing2023/exposiciones/raices/21.png";
import img22 from "../../../../assets/imgs/landing2023/exposiciones/raices/22.png";
import img23 from "../../../../assets/imgs/landing2023/exposiciones/raices/23.png";
import img24 from "../../../../assets/imgs/landing2023/exposiciones/raices/24.png";

const data_one = [
    {
        img: img01
    },
    {
        img: img02
    },
    {
        img: img03
    },
    {
        img: img04
    },
    {
        img: img05
    },
    {
        img: img06
    },
    {
        img: img07
    },
    {
        img: img08
    },
    {
        img: img09
    },
    {
        img: img10
    },
    {
        img: img11
    },
    {
        img: img12
    },
    {
        img: img13
    },
    {
        img: img14
    },
    {
        img: img15
    },
    {
        img: img16
    },
    {
        img: img17
    },
    {
        img: img18
    },
    {
        img: img19
    },
    {
        img: img20
    },
    {
        img: img21
    },
    {
        img: img22
    },
    {
        img: img23
    },
    {
        img: img24
    }
];

const FullSizeOverlayDialog = ({ visible, selImg }) => {
    const dialogRef = useRef(null);
    useEffect(() => {
        if (dialogRef.current) {
            if (visible && dialogRef.current?.open === false) {
                if (typeof dialogRef.current?.showModal === "function") {
                    dialogRef.current?.showModal();
                }
                dialogRef.current?.focus();
            } else {
                dialogRef.current?.close();
            }
        }
    }, [visible]);

    return (
        <dialog
            ref={dialogRef}
            className="gallery-dialog"
            aria-label="modal"
            aria-modal>
            <div className="dialog-content d-flex align-items-center">
                <img src={selImg} className="img-fluid" alt="gallery element" />
            </div>
        </dialog>
    );
};
const Aswer = () => {
    const [visible, setVisible] = useState(false);
    const [selImage, setSelImage] = useState(null);

    const handleClick = (selEl) => {
        setSelImage(selEl);
        setVisible(true);
    };
    return (
        <div>
            {visible ? (
                <div onClick={() => setVisible(false)}>
                    <FullSizeOverlayDialog visible={visible} selImg={selImage} />
                </div>
            ) : null}
            <Row noGutters className="mx-auto my-5">
                {data_one.map(({ img }, i) => (
                    <Col onClick={() => handleClick(img)} md={4} sm={12} key={i}>
                        <img
                            src={img}
                            alt={`expo 100 fachadas, fachada número ${i + 1}`}
                            className="w-100 p-1 img-fluid w-100"
                        />
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default Aswer;
