import React from 'react';
import { Container, Row, Col } from 'reactstrap'

import "../../../style/home.css"

function UsFirst() {
    return (
        <>
            <Container className="bg-firstUs " fluid> 
            </Container>
            <Container className="d-flex flex-column justify-content-center" fluid>
            <Row className="mt-5"> 
                <Col md={1}></Col>
                <Col >
                    <h2 className="text-gray font-weight-bold">
                        Nosotros
                    </h2>

                    <h6 className=" my-4 font-weight-bold text-dark ">
                        Primera Edición 
                    </h6>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col md={1}></Col>
                <Col md={10}>
                    <a href="/primeraedicion">
                        <h5 className="my-5 text-center text-pink font-weight-bold">
                            <u>Ver exposición</u>
                        </h5>
                    </a>
                </Col>
                <Col md={1}></Col>
            </Row>
            </Container>
        </>
    )
}

export default UsFirst