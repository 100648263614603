import React from "react";
import { Container, Row, Col } from "reactstrap";
import Layout from "../../Layout";
function Vestigios() {
    return (
        <>
        <Layout>
            <Container className="bg-fachadas" fluid></Container>
            <Container fluid className="my-3">
                <Row className="my-md-5 my-3">
                    <Col md={1}></Col>
                    <Col>
                        <h6 className="mt-4 font-weight-light text-dark ">
                            Cien fachadas, un millón de historias
                            <br />
                            Mal de Ojo.
                        </h6>
                    </Col>
                </Row>
                <Row>
                    <Col md={1}></Col>
                    <Col>
                        <h5>
                            El Centro Histórico de la ciudad de Puebla está catalogado como
                            Patrimonio Histórico de la Humanidad en la categoría de bienes
                            culturales en la región de América latina y el caribe, otorgado
                            por la Organización de las Naciones Unidas para la Educación, la
                            Ciencia y la Cultura (UNESCO) en 1987 debido a su importancia de
                            carácter histórico y su riqueza arquitectónica de estilo barroco y
                            neoclásico. <br />
                            <br />
                            “Cien fachadas, un millón de historias” es un proyecto fotográfico
                            que abarca una selección de 100 inmuebles o sitios de interés de
                            carácter histórico, de relevancia cultural, estética o
                            arquitectónica como ciudad viva. Apartar todo elemento ajeno y
                            aislar la fachada ayuda a enfocarse en el esplendor de las
                            edificaciones y sus detalles que la componen, ya que cada una de
                            ellas tiene rasgos tan distintivos que los identifican y a la vez
                            los distinguen de sus similares. En una sola calle podríamos
                            observar más de una decena de ellas, pero no por esto son menos
                            importantes, sino cada uno tiene su espacio y su lugar como forma
                            única de arte, vida y armonía.
                            <br />
                            <br />
                            Como resultado de este trabajo, se crearon a partir de cientos de
                            tomas fotográficas, el collage y la edición digital 100 imágenes
                            únicas de los inmuebles catalogados como Patrimonio Histórico,
                            cada recinto alberga en su interior historias del día a día, de la
                            ciudad, de sus antepasados y de sus leyendas; también no podemos
                            dejar de admirar el esplendor de las calles y sus atardeceres,
                            costumbres, personajes, aromas, sonidos y de la infinidad de
                            sensaciones que guardan sus espacios.
                        </h5>
                    </Col>
                    <Col md={1}></Col>
                </Row>
            </Container>
            <hr
                style={{
                    width: "100%",
                    background: "#1d1d1b",
                    marginTop: 0,
                    marginLeft: 0
                }}
            />
            <Container fluid>
                <Row className="my-3">
                    <Col md={1}></Col>
                </Row>
                <Row className="mb-5">
                    <Col md={1}></Col>
                    <Col>
                        <h5>
                            Mal de Ojo, México, 1985. Vive y trabaja en la ciudad de Puebla.{" "}
                            <br />
                            Estudió la licenciatura en Diseño y Artes Plásticas. Trabaja
                            principalmente con la fotografía, especialmente con la imagen
                            construida digitalmente. <br />
                            <br />
                            Es parte del programa PICS del Centro de la Imagen (Secretaría de
                            Cultura de México), fue moderador de la mesa de Artes Plásticas
                            del proyecto ‘Diálogos en Resiliencia' de la UNESCO 2020 (México).
                            Su obra es parte de la colección del Cooper Hewiit Smithsonian
                            Design Museum en Nueva York (USA). Invitado en el Festival de Arte
                            Emergente CENTRAL 2019 como ponente (Guatemala). Su trabajo fue
                            portada de la Revista National Geographic a nivel mundial del mes
                            de junio de 2018 (ganadora de varios premios y reconocimientos a
                            nivel internacional). Ha expuesto de manera individual en México y
                            de manera colectiva en Estados Unidos, Rusia, Polonia, Ecuador y
                            Bolivia, entre otros. Ha colaborado con National Geographic
                            Magazine (USA), Greenpeace (Bélgica y España), ONU (Brasil), Hugo
                            Boss (Alemania), Lowes Magazine (USA), Editorial Penguin Random
                            House (España), Codice Edizioni (Italia), Studio K (República
                            Checa), Udvliking Magazine (Dinamarca), University of
                            Milano-Bicocca UNIMIB (Italia), Consejo Internacional/Nacional
                            Cerrajero (México).
                        </h5>
                    </Col>
                    <Col md={1}></Col>
                </Row>
                <Row>
                    <Col md={1}></Col>
                    <Col className="d-flex justify-content-start">
                        <a
                            href="https://Instagram.com/maldeojovisual"
                            target="_blank"
                            rel="noopener noreferrer">
                            <u>
                                <p className="font-weight-bold">Instagram</p>
                            </u>
                        </a>
                        <p className=" mx-5">|</p>
                        <a
                            href="https://Facebook.com/maldeojovisual"
                            target="_blank"
                            rel="noopener noreferrer">
                            <u>
                                <p className="font-weight-bold">Facebook</p>
                            </u>
                        </a>
                    </Col>
                </Row>
            </Container>
            <hr
                style={{
                    width: "100%",
                    background: "#1d1d1b",
                    marginTop: 0,
                    marginLeft: 0
                }}
            />
            <Container fluid className="d-flex justify-items-center">
                <Row className="justify-content-center">
                    <Col md={10} sm={12}>

                    </Col>
                </Row>
            </Container>
        </Layout>
        </>
    );
}

export default Vestigios;
