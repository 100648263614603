import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {
  Home,
  FoodDesign,
  Territory,
  Barroquito,
  First,
  Second,
  Third,
  Fourth,
  WeAre,
  Alliances,
  MexicanLottery,
  Contact,
  FirstEditionUs,
  SecondEditionUs,
  ThirdEditionUs,
  FourthEditionUs,
  Objects,
  Pertenencia,
  DesignNightPage,
  TallerTiposMobiles,
  TallerArtesanales,
  LatinoamericanInterior,
  ExpoVirtual,
} from './routes'
import { Durango, GenderViolence, Vestigios, Raices, ArteEmergente} from './components/sections/home';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/vestigios">
            <Vestigios />
          </Route>
          <Route exact path="/raices">
            <Raices />
          </Route>
          <Route exact path="/arte-emergente">
            <ArteEmergente />
          </Route>
          <Route exact path="/violencia-genero">
            <GenderViolence />
          </Route>
          <Route exact path="/nosotros">
            <WeAre />
          </Route>
          <Route exact path="/contacto">
            <Contact />
          </Route>
          <Route exact path="/aliados">
            <Alliances />
          </Route>
          <Route exact path="/pertenencia">
            <Pertenencia />
          </Route>
          <Route exact path="/ciudad-invitada">
            <Durango />
          </Route>
          <Route exact path="/exposiciones">
            <ExpoVirtual />
          </Route>
          <Route exact path="/primera-edicion">
            <First />
          </Route>
          <Route exact path="/segunda-edicion">
            <Second />
          </Route>
          <Route exact path="/tercera-edicion">
            <Third />
          </Route>
          <Route exact path="/cuarta-edicion">
            <Fourth />
          </Route>
          <Route exact path="/eventos">
            <Objects />
          </Route>
          <Route exact path="/foodesign">
            <FoodDesign />
          </Route>
          <Route exact path="/estudio-territorio">
            <Territory />
          </Route>
          <Route exact path="/art-toy">
            <Barroquito />
          </Route>
          <Route exact path="/design-night">
            <DesignNightPage />
          </Route>
          <Route exact path="/tipos-moviles">
            <TallerTiposMobiles />
          </Route>
          <Route exact path="/artesanales">
            <TallerArtesanales />
          </Route>
          <Route exact path="/loteria">
            <MexicanLottery />
          </Route>
          <Route exact path="/interiorismo">
            <LatinoamericanInterior />
          </Route>
          <Route exact path="/primera-edicion-nosotros">
            <FirstEditionUs />
          </Route>
          <Route exact path="/segunda-edicion-nosotros">
            <SecondEditionUs />
          </Route>
          <Route exact path="/tercera-edicion-nosotros">
            <ThirdEditionUs />
          </Route>
          <Route exact path="/cuarta-edicion-nosotros">
            <FourthEditionUs />
          </Route>
        </Switch>

      </div>
    </Router>
  );
}

export default App;