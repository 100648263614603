import React from "react"
import PropTypes from "prop-types"

import "bootstrap/dist/css/bootstrap.min.css"

import { Navb, Footer } from "./"


const Layout = ({ children }) => {
    return (
    <>
        <Navb/>
        <main>{children}</main>
        <Footer/>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
