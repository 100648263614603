import React from 'react';
import { Container, Row, Col } from 'reactstrap'
import "../../../style/barroquito.css"
import img1 from "../../../assets/imgs/barroquito/segunda/1.png"
import img2 from "../../../assets/imgs/barroquito/segunda/2.png"
import img3 from "../../../assets/imgs/barroquito/segunda/3.png"
import img4 from "../../../assets/imgs/barroquito/segunda/4.png"
import img5 from "../../../assets/imgs/barroquito/segunda/5.png"
import img6 from "../../../assets/imgs/barroquito/segunda/6.png"
import img7 from "../../../assets/imgs/barroquito/segunda/7.png"
import img8 from "../../../assets/imgs/barroquito/segunda/8.png"
import img9 from "../../../assets/imgs/barroquito/segunda/9.png"
import img10 from "../../../assets/imgs/barroquito/segunda/10.png"
import img11 from "../../../assets/imgs/barroquito/segunda/11.png"
import img12 from "../../../assets/imgs/barroquito/segunda/12.png"
import img13 from "../../../assets/imgs/barroquito/segunda/13.png"
import img14 from "../../../assets/imgs/barroquito/segunda/14.png"
import img15 from "../../../assets/imgs/barroquito/segunda/15.png"
import img16 from "../../../assets/imgs/barroquito/segunda/16.png"
import img17 from "../../../assets/imgs/barroquito/segunda/17.png"
import img18 from "../../../assets/imgs/barroquito/segunda/18.png"
import img19 from "../../../assets/imgs/barroquito/segunda/19.png"
import img20 from "../../../assets/imgs/barroquito/segunda/20.png"
import img21 from "../../../assets/imgs/barroquito/segunda/21.png"
import img22 from "../../../assets/imgs/barroquito/segunda/22.png"
import img23 from "../../../assets/imgs/barroquito/segunda/23.png"
import img24 from "../../../assets/imgs/barroquito/segunda/24.png"
import img25 from "../../../assets/imgs/barroquito/segunda/25.png"
import img26 from "../../../assets/imgs/barroquito/segunda/26.png"

import img27 from "../../../assets/imgs/barroquito/segunda/27.png"
import img28 from "../../../assets/imgs/barroquito/segunda/28.png"
import img29 from "../../../assets/imgs/barroquito/segunda/29.png"
import img30 from "../../../assets/imgs/barroquito/segunda/30.png"
import img31 from "../../../assets/imgs/barroquito/segunda/31.png"
import img32 from "../../../assets/imgs/barroquito/segunda/32.png"
import img33 from "../../../assets/imgs/barroquito/segunda/33.png"
import img34 from "../../../assets/imgs/barroquito/segunda/34.png"
import img35 from "../../../assets/imgs/barroquito/segunda/35.png"
import img36 from "../../../assets/imgs/barroquito/segunda/36.png"
import img37 from "../../../assets/imgs/barroquito/segunda/37.png"
import img38 from "../../../assets/imgs/barroquito/segunda/38.png"
import img39 from "../../../assets/imgs/barroquito/segunda/39.png"
import img40 from "../../../assets/imgs/barroquito/segunda/40.png"
import img41 from "../../../assets/imgs/barroquito/segunda/41.png"
import img42 from "../../../assets/imgs/barroquito/segunda/42.png"
import img43 from "../../../assets/imgs/barroquito/segunda/43.png"
import img44 from "../../../assets/imgs/barroquito/segunda/44.png"
import img45 from "../../../assets/imgs/barroquito/segunda/45.png"
import img46 from "../../../assets/imgs/barroquito/segunda/46.png"
import img47 from "../../../assets/imgs/barroquito/segunda/47.png"
import img48 from "../../../assets/imgs/barroquito/segunda/48.png"
import img49 from "../../../assets/imgs/barroquito/segunda/49.png"
import img50 from "../../../assets/imgs/barroquito/segunda/50.png"
import img51 from "../../../assets/imgs/barroquito/segunda/51.png"
import img52 from "../../../assets/imgs/barroquito/segunda/52.png"
import img53 from "../../../assets/imgs/barroquito/segunda/53.png"
import img54 from "../../../assets/imgs/barroquito/segunda/54.png"
import img55 from "../../../assets/imgs/barroquito/segunda/55.png"
import img56 from "../../../assets/imgs/barroquito/segunda/56.png"
import img57 from "../../../assets/imgs/barroquito/segunda/57.png"
import img58 from "../../../assets/imgs/barroquito/segunda/58.png"
import img59 from "../../../assets/imgs/barroquito/segunda/59.png"
import img60 from "../../../assets/imgs/barroquito/segunda/60.png"
import img61 from "../../../assets/imgs/barroquito/segunda/61.png"
import img62 from "../../../assets/imgs/barroquito/segunda/62.png"

const firstcontent = [
    {
    numberpiece : "Pieza 1 de 62",
    artist: "Audrey Huertas",
    src: img1,
    socials:"https://www.instagram.com/audreyhuertas/"
    },
    {
    numberpiece : "Pieza 2 de 62",
    artist: "Alan Urbina",
    src: img2,
    socials:"https://www.alanutoys.com/"
    },
    {
    numberpiece : "Pieza 3 de 62",
    artist: "Buque ",
    src: img3,
    socials:"https://www.instagram.com/yosoybuque/"
    },
    {
    numberpiece : "Pieza 4 de 62",
    artist: "Chucho Rojas",
    src: img4,
    socials:"https://www.instagram.com/chuchorojass"
    },
    {
    numberpiece : "Pieza 5 de 62",
    artist: "El Hooligan",
    src: img5,
    socials:"https://www.instagram.com/el_hooligan/"
    },
    {
    numberpiece : "Pieza 6 de 62",
    artist: "Escuincle",
    src: img6,
    socials:"https://www.bazarama.com/t/escuincle"
    },
    {
    numberpiece : "Pieza 7 de 62",
    artist: "Frank Mysterio",
    src: img7,
    socials:"https://frankmysterio.bigcartel.com/"
    },
        {
    numberpiece : "Pieza 8 de 62",
    artist: "Kimbal",
    src: img8,
    socials:"https://www.instagram.com/kimbal_estudio/"
    },
    {
    numberpiece : "Pieza 9 de 62",
    artist: "Jenny Cherry",
    src: img9,
    socials:"https://www.instagram.com/jennycherryart/"
    },
    {
    numberpiece : "Pieza 10 de 62",
    artist: "Jhon Leon",
    src: img10,
    socials:"https://www.instagram.com/jhon_leon_art"
    },
    {
    numberpiece : "Pieza 11 de 62",
    artist: "La Breens",
    src: img11,
    socials:"https://www.instagram.com/labreens/"
    },
    {
    numberpiece : "Pieza 12 de 62",
    artist: "Máximo",
    src: img12,
    socials:"https://www.instagram.com/maximo_paintingstudio/"
    },
    {
    numberpiece : "Pieza 13 de 62",
    artist: "Mike Wynter",
    src: img13,
    socials:"https://www.instagram.com/mike_wynter/"
    },
    {
    numberpiece : "Pieza 14 de 62",
    artist: "Mitote",
    src: img14,
    socials:"https://www.instagram.com/mr_mitote/"
    },
    {
    numberpiece : "Pieza 15 de 62",
    artist: "Ouergraffix",
    src: img15,
    socials:"https://www.instagram.com/ouergraffix/"
    },
    {
    numberpiece : "Pieza 16 de 62",
    artist: " Piti Piti",
    src: img16,
    socials:"https://www.facebook.com/alopitipiti "
    },
    {
    numberpiece : "Pieza 17 de 62",
    artist: "Sarukaku Estudio",
    src: img17,
    socials:"https://www.sarukaku.com/"
    },
    {
    numberpiece : "Pieza 18 de 62",
    artist: "Shiffa",
    src: img18,
    socials:"https://shiffa.bigcartel.com/"
    },
    {
    numberpiece : "Pieza 19 de 62",
    artist: "Sr. Venado",
    src: img19,
    socials:" https://www.instagram.com/sr.venado/"
    },
    {
    numberpiece : "Pieza 20 de 62",
    artist: "Yoste",
    src: img20,
    socials:"https://www.instagram.com/yoste1/"
    },
    {
    numberpiece : "Pieza 21 de 62",
    artist: "Alejandro Gonay",
    src: img21,
    socials:"https://www.instagram.com/alejandro.gonay"
    },
    {
    numberpiece : "Pieza 22 de 62",
    artist: "Alexia Moca",
    src: img22,
    socials:"https://www.instagram.com/alexia_moca"
    },
    {
    numberpiece : "Pieza 23 de 62",
    artist: "Amor Platería",
    src: img23,
    socials:"www.facebook.com/amor.plateria"
    },
    {
    numberpiece : "Pieza 24 de 62",
    artist: "Aurum Restauración ",
    src: img24,
    socials:"https://www.instagram.com/Aurum.restauración00"
    },
    {
    numberpiece : "Pieza 25 de 62",
    artist: "Bearhands ",
    src: img25,
    socials:"https://www.instagram.com/bearhands_bg"
    },
    {
    numberpiece : "Pieza 26 de 62",
    artist: "Capicúa",
    src: img26,
    socials:"https://www.instagram.com/cursoscapicúa"
    },
    {
    numberpiece : "Pieza 27 de 62",
    artist: "Carlos Galván",
    src: img27,
    socials:"https://www.instagram.com/hippie_totopo"
    },
    {
    numberpiece : "Pieza 28 de 62",
    artist: "Ces Quintana ",
    src: img28,
    socials:"https://www.facebook.com/cesquintana"
    },
    {
    numberpiece : "Pieza 29 de 62",
    artist: "Daniel Hernández",
    src: img29,
    socials:"https://www.instagram.com/Daniel_Barstar"
    },
    {
    numberpiece : "Pieza 30 de 62",
    artist: "Der Müll Toys",
    src: img30,
    socials:"https://www.instagram.com/dermulltoys"
    },
    {
    numberpiece : "Pieza 31 de 62",
    artist: "Diego López",
    src: img31,
    socials:"https://www.instagram.com/diego_lopezde"
    },
    {
    numberpiece : "Pieza 32 de 62",
    artist: "Fernanda Jrz",
    src: img32,
    socials:"https://www.instagram.com/Fernanda.jrzsan"
    },
    {
    numberpiece : "Pieza 33 de 62",
    artist: "Fernanda Rodríguez",
    src: img33,
    socials:"https://www.instagram.com/fer_zombie/"
    },
    {
    numberpiece : "Pieza 34 de 62",
    artist: "Flordeliz Lezlie",
    src: img34,
    socials:"https://www.instagram.com/flordelizlezlie"
    },
    {
    numberpiece : "Pieza 35 de 62",
    artist: "Francisco Morales ",
    src: img35,
    socials:"https://www.instagram.com/franco_mck"
    },
    {
    numberpiece : "Pieza 36 de 62",
    artist: "Fred Peralta ",
    src: img36,
    socials:"https://www.instagram.com/Peralta_Fred"
    },
    {
    numberpiece : "Pieza 37 de 62",
    artist: "Karla Midori ",
    src: img37,
    socials:"https://www.instagram.com/karlamidorii"
    },
    {
    numberpiece : "Pieza 38 de 62",
    artist: "Knife",
    src: img38,
    socials:"https://www.instagram.com/luis.k.rivera"
    },
    {
    numberpiece : "Pieza 39 de 62",
    artist: "Laura Torija ",
    src: img39,
    socials:"https://www.instagram.com/arual_te/"
    },
    {
    numberpiece : "Pieza 40 de 62",
    artist: "Licetas ",
    src: img40,
    socials:"https://www.instagram.com/licetas.5"
    },
    {
    numberpiece : "Pieza 41 de 62",
    artist: "Los Tiliches de Tania",
    src: img41,
    socials:"https://www.instagram.com/Lostilichesdetania"
    },
    {
    numberpiece : "Pieza 42 de 62",
    artist: "Luis Felipe Campillo ",
    src: img42,
    socials:"https://www.instagram.com/campillomarquez"
    },
    {
    numberpiece : "Pieza 43 de 62",
    artist: "Luna Bandid ",
    src: img43,
    socials:"www.artstation.com/lunabandid"
    },
    {
    numberpiece : "Pieza 44 de 62",
    artist: "Mariana García ",
    src: img44,
    socials:"https://www.instagram.com/Mariianakim"
    },
    {
    numberpiece : "Pieza 45 de 62",
    artist: "Marianne Green ",
    src: img45,
    socials:"https://www.instagram.com/palebluedeer"
    },
    {
    numberpiece : "Pieza 46 de 62",
    artist: "Marian Cheshire",
    src: img46,
    socials:"https://www.instagram.com/MarianCheshire"
    },
    {
    numberpiece : "Pieza 47 de 62",
    artist: "Meketrefe",
    src: img47,
    socials:"https://www.instagram.com/meketrefe01"
    },
    {
    numberpiece : "Pieza 48 de 62",
    artist: "Moavi Studio",
    src: img48,
    socials:"https://www.instagram.com/moavi.studio/"
    },
    {
    numberpiece : "Pieza 49 de 62",
    artist: "Monik Foyo",
    src: img49,
    socials:"https://www.instagram.com/MonikFoyoArt"
    },
    {
    numberpiece : "Pieza 50 de 62",
    artist: "Mopet Project: Valeria Quiroz",
    src: img50,
    socials:"https://www.instagram.com/valeliberta/"
    },
    {
    numberpiece : "Pieza 51 de 62",
    artist: "Mopet Project: Luis Ángel Rosales",
    src: img51,
    socials:"https://www.instagram.com/mopet_project/"
    },
    {
    numberpiece : "Pieza 52 de 62",
    artist: "Mr. Médula",
    src: img52,
    socials:"https://www.instagram.com/mr.medula"
    },
    {
    numberpiece : "Pieza 53 de 62",
    artist: "Niña de Papel",
    src: img53,
    socials:"https://www.instagram.com/dani.con.i/"
    },
    {
    numberpiece : "Pieza 54 de 62",
    artist: "Picsa de Cotsco",
    src: img54,
    socials:"https://www.instagram.com/picsadecotsco"
    },
    {
    numberpiece : "Pieza 55 de 62",
    artist: "Sebastian Inclán",
    src: img55,
    socials:"https://www.instagram.com/inclansurrealismomexico"
    },
    {
    numberpiece : "Pieza 56 de 62",
    artist: "Sebastian Inclán",
    src: img56,
    socials:"https://www.instagram.com/lapalabraflorida/"
    },
    {
    numberpiece : "Pieza 57 de 62",
    artist: "Sergio Mena",
    src: img57,
    socials:"https://www.instagram.com/sergiomena_"
    },
    {
    numberpiece : "Pieza 58 de 62",
    artist: "Sukem",
    src: img58,
    socials:"https://www.instagram.com/soysukem"
    },
    {
    numberpiece : "Pieza 59 de 62",
    artist: "Trinity School MX",
    src: img59,
    socials:"https://www.instagram.com/TrinitySchoolMx"
    },
    {
    numberpiece : "Pieza 60 de 62",
    artist: "Valenza *Lilian López Conde",
    src: img60,
    socials:"https://www.instagram.com/freelancer.valenza"
    },
    {
    numberpiece : "Pieza 61 de 62",
    artist: "Wolf Solitary",
    src: img61,
    socials:"https://www.instagram.com/Wolf_solitary444"
    },
    {
    numberpiece : "Pieza 62 de 62",
    artist: "Zotzz",
    src: img62,
    socials:"https://www.instagram.com/zotzz_"
    }
]

function SecondEdition(){
    return(
        <Container className="d-flex flex-column justify-content-center" fluid>
            <Row className="my-5"> 
                <Col md={1}></Col>
                <Col >
                    <h2 className=" font-weight-bold text-gray">
                        Art Toy - Barroquito
                    </h2>

                    <h6 className=" mt-4 font-weight-bold text-dark ">
                        Segunda Edición
                </h6>
                </Col>
            </Row>
            <Row md={6}>
                {firstcontent.map(({numberpiece, artist, src, socials} , i )=> (
                    <>
                    <Col md={1}></Col>
                    <Col sm={2} className="d-flex flex-column align-items-center my-4 px-md-0 px-5">
                        <img src={src} alt="barroquito" className="img-fluid barroquito_img"/>
                        <div className="d-flex flex-column justify-content-center  align-items-center text-md-left text-center">
                            <h5>
                                {numberpiece}
                            </h5>
                            <h3 className="font-weight-bold text-center ">
                                {artist}
                            </h3>
                            <a href={socials} target="_blank" rel="noopener noreferrer">
                                <h5 className="d-md-flex d-none text-pink font-weight-bold">
                                    <u>Ver más sobre el artista</u>
                                </h5>
                                <h5 className="d-md-none d-flex text-pink font-weight-bold">
                                    <u>Ver más<br/> sobre el artista</u>
                                </h5>
                            </a>
                        </div>
                    </Col>
                    <Col md={1}></Col>
                    </>
                ))}
            </Row>
        </Container>
    )
}

export default SecondEdition