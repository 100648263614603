import React from 'react';
import { Container, Row, Col } from 'reactstrap'
import Expos from './Expos'
import "../../../../style/home.css"

function VirtualShowcase() {
    return (
        <>
            <Container className="bg-virtual-showcase d-flex flex-column justify-content-center " fluid>
            </Container>
            <Container className="my-5" fluid>
                <Row>
                    <Col md={1} sm={12}></Col>
                    <Col md={10} sm={12}>
                        <h1 className="font-weight-bold mt-3 text-dark ">
                            Exposiciones Virtuales
                        </h1>
                    </Col>
                    <Col md={1} sm={12}></Col>
                </Row>
                <Row>
                    <Col md={1} sm={12}></Col>
                    <Col>
                    <Expos />
                    </Col>
                    <Col md={1} sm={12}></Col>
                </Row>
            </Container>

        </>
    )
}

export default VirtualShowcase