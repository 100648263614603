import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "reactstrap";
import img01 from "../../../../assets/imgs/landing2023/exposiciones/violencia/01.jpg";
import img02 from "../../../../assets/imgs/landing2023/exposiciones/violencia/02.jpg";
import img03 from "../../../../assets/imgs/landing2023/exposiciones/violencia/03.jpg";
import img04 from "../../../../assets/imgs/landing2023/exposiciones/violencia/04.jpg";
import img05 from "../../../../assets/imgs/landing2023/exposiciones/violencia/05.jpg";
import img06 from "../../../../assets/imgs/landing2023/exposiciones/violencia/06.jpg";
import img07 from "../../../../assets/imgs/landing2023/exposiciones/violencia/07.jpg";
import img08 from "../../../../assets/imgs/landing2023/exposiciones/violencia/08.jpg";
import img09 from "../../../../assets/imgs/landing2023/exposiciones/violencia/09.jpg";
import img10 from "../../../../assets/imgs/landing2023/exposiciones/violencia/10.jpg";
import img11 from "../../../../assets/imgs/landing2023/exposiciones/violencia/11.jpg";
import img12 from "../../../../assets/imgs/landing2023/exposiciones/violencia/12.jpg";
import img13 from "../../../../assets/imgs/landing2023/exposiciones/violencia/13.jpg";
import img14 from "../../../../assets/imgs/landing2023/exposiciones/violencia/14.jpg";
import img15 from "../../../../assets/imgs/landing2023/exposiciones/violencia/15.jpg";
import img16 from "../../../../assets/imgs/landing2023/exposiciones/violencia/16.jpg";
import img17 from "../../../../assets/imgs/landing2023/exposiciones/violencia/17.jpg";
import img18 from "../../../../assets/imgs/landing2023/exposiciones/violencia/18.jpg";
import img19 from "../../../../assets/imgs/landing2023/exposiciones/violencia/19.JPG";
import img20 from "../../../../assets/imgs/landing2023/exposiciones/violencia/20.jpg";
import img21 from "../../../../assets/imgs/landing2023/exposiciones/violencia/21.jpg";
import img22 from "../../../../assets/imgs/landing2023/exposiciones/violencia/22.jpg";
import img23 from "../../../../assets/imgs/landing2023/exposiciones/violencia/23.jpg";
import img24 from "../../../../assets/imgs/landing2023/exposiciones/violencia/24.jpg";
import img25 from "../../../../assets/imgs/landing2023/exposiciones/violencia/25.jpg";
import img26 from "../../../../assets/imgs/landing2023/exposiciones/violencia/26.jpg";
import img27 from "../../../../assets/imgs/landing2023/exposiciones/violencia/27.jpg";
import img28 from "../../../../assets/imgs/landing2023/exposiciones/violencia/28.jpg";
import img29 from "../../../../assets/imgs/landing2023/exposiciones/violencia/29.jpg";
import img30 from "../../../../assets/imgs/landing2023/exposiciones/violencia/30.jpg";
import img31 from "../../../../assets/imgs/landing2023/exposiciones/violencia/31.jpg";

const data_one = [
  {
    img: img01
  },
  {
    img: img02
  },
  {
    img: img03
  },
  {
    img: img04
  },
  {
    img: img05
  },
  {
    img: img06
  },
  {
    img: img07
  },
  {
    img: img08
  },
  {
    img: img09
  },
  {
    img: img10
  },
  {
    img: img11
  },
  {
    img: img12
  },
  {
    img: img13
  },
  {
    img: img14
  },
  {
    img: img15
  },
  {
    img: img16
  },
  {
    img: img17
  },
  {
    img: img18
  },
  {
    img: img19
  },
  {
    img: img20
  },
  {
    img: img21
  },
  {
    img: img22
  },
  {
    img: img23
  },
  {
    img: img24
  },
  {
    img: img25
  },
  {
    img: img26
  },
  {
    img: img27
  },
  {
    img: img28
  },
  {
    img: img29
  },
  {
    img: img30
  },
  {
    img: img31
  }
];

const FullSizeOverlayDialog = ({ visible, selImg }) => {
  const dialogRef = useRef(null);
  useEffect(() => {
    if (dialogRef.current) {
      if (visible && dialogRef.current?.open === false) {
        if (typeof dialogRef.current?.showModal === "function") {
          dialogRef.current?.showModal();
        }
        dialogRef.current?.focus();
      } else {
        dialogRef.current?.close();
      }
    }
  }, [visible]);

  return (
    <dialog
      ref={dialogRef}
      className="gallery-dialog"
      aria-label="modal"
      aria-modal>
      <div className="dialog-content d-flex align-items-center">
        <img src={selImg} className="img-fluid" alt="gallery element" />
      </div>
    </dialog>
  );
};
const Violence = () => {
  const [visible, setVisible] = useState(false);
  const [selImage, setSelImage] = useState(null);

  const handleClick = (selEl) => {
    setSelImage(selEl);
    setVisible(true);
  };
  return (
    <div>
      {visible ? (
        <div onClick={() => setVisible(false)}>
          <FullSizeOverlayDialog visible={visible} selImg={selImage} />
        </div>
      ) : null}
      <Row noGutters className="mx-auto my-5">
        {data_one.map(({ img }, i) => (
          <Col onClick={() => handleClick(img)} md={4} sm={12} key={i}>
            <img
              src={img}
              alt={`expo 100 fachadas, fachada número ${i + 1}`}
              className="w-100 p-1 img-fluid w-100"
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Violence;
