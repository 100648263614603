import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap'
import "../../../style/home.css"
import second from "../../../assets/pdf/segunda.pdf"
function UsFourth() {
    return (
        <>
            <Container className="bg-fourthUs " fluid>
            </Container>
            <Container className="d-flex flex-column justify-content-center" fluid>
                <Row className="mt-5">
                    <Col md={1}></Col>
                    <Col >
                        <h2 className="text-gray font-weight-bold">
                            Nosotros
                        </h2>

                        <h6 className=" my-4 font-weight-bold text-dark ">
                            Cuarta Edición
                        </h6>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col md={1}></Col>
                    <Col md={10}>
                        <iframe src={second} width="100%" height="600" title="Second Edition PDF" className="d-md-flex d-none">
                        </iframe>
                        <a href={second} className="d-flex d-md-none align-self-center justify-content-center text-center" download>
                            <Button color="primary">
                                Descarga PDF
                            </Button>
                        </a>
                        <a href="/segundaedicion">
                            <h5 className="my-5 text-center text-pink font-weight-bold">
                                <u>Ver exposición</u>
                            </h5>
                        </a>
                    </Col>
                    <Col md={1}></Col>
                </Row>

            </Container>
        </>
    )
}

export default UsFourth