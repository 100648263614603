import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../../../style/home.css"

function Enlace(){
    return(
        <>
        <Container className="d-flex flex-column px-4 px-md-0 mb-5" fluid>
            <Row className="mt-5 "> 
                <Col md={1}></Col>
                <Col >
                    <h6 className=" my-4 font-weight-light text-dark ">
                        Enlace internacional
                    </h6>
                </Col>
            </Row>
            <Row>
                <Col md={1}> </Col>
                <Col >
                    <h5>
                        Compatibles a la Agenda 2030, Design Week Puebla trabaja en favor del reconocimiento de contextos específicos de lugares y asentamientos, educación para la sostenibilidad, promoción del turismo sostenible, interrelación de igualdad de genero y cultura, asi como infraestructuras de calidad y protección de patrimonio cultural, por mencionar algunos. <br/> <br/>

                        DWP promueve alianzas estrátegicas que comparten e impulsan nuestro pensamiento, sentir y objetivos tanto a corto, mediano y largo plazo, incidiendo en el Territorio Transversal. Hemos establecido una estructura con aliados educativos, culturales, sociales tanto públicos y privados a nivel nacional, lo que nos impulsó a tejer redes a nivel internacional. Colaborando con Montana, Elisava, Manchester Design, IED Madrid, Barcelona Design Week, Lima Design Week, y la World Design Weeks como parte de la red internacional de la región LATAM, mediante la campaña #UnidosPorElDiseno buscando fortalecer a la región, así como crear y nutrir a la Comunidad Creativa de nuestro Estado.
                    </h5>
                </Col>
                <Col md={1}> </Col>
            </Row>
        </Container>
        </>
    )
}

export default Enlace