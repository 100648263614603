import React from 'react';
import { Layout } from '../components'
import { AlliesPage } from "../components/sections/home"
import "../style/home.css"

function Alliances(){
    return(
        <Layout>
            <AlliesPage/>
        </Layout>
    )
}

export default Alliances;
