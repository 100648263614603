import React from 'react';
import { Layout } from '../components'
import { MobileTypes } from "../components/sections/program2022/"
import "../style/home.css"

function TallerTiposMobiles() {
    return (
        <Layout>
            <MobileTypes />
        </Layout>
    )
}

export default TallerTiposMobiles;
