import React from 'react';
import { Container, Row, Col } from 'reactstrap'

import "../../../../style/home.css"

function MobileTypes() {
    return (
        <>
            <Container className="bg-designnight d-flex flex-column justify-content-center " fluid>
            </Container>
            <Container className="my-5" fluid>
                <Row className="mt-5">
                    <Col md={1}></Col>
                    <Col >
                        <h6>
                            Taller de Tipos Móviles
                        </h6>
                    </Col>
                </Row>
                <Row>
                    <Col md={1}></Col>
                    <Col >
                        <h4 className="my-md-3 my-3">
                            Imparte: - <br/>
                            - Fecha: - <br />
                            - Duración: - <br />
                            - Lugar: Valenza (Av. Venustiano Carranza 205, San Baltazar Campeche, Puebla, Pue.) <br />
                            - Materiales: Incluidos <br /><br />
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt
                            ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation
                            ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor
                            in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero
                            eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore
                            te feugait nulla facilisi. <br />
                            Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt
                            ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation
                            ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. <br />
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt
                            ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud <br /><br />
                            Registro Valenza Whatsapp: 2224371384 <br />
                            Costo: $ - MXN. <br />
                            Aparta con el 50% <br />
                        </h4>
                    </Col>
                    <Col md={1}></Col>
                </Row>
            </Container>
        </>
    )
}

export default MobileTypes