import React from 'react';
import { Layout } from '../components'
import { ThirdEdition } from "../components/sections/editions"
import "../style/home.css"

function Third(){
    return(
        <Layout>
            <ThirdEdition/>
        </Layout>
    )
}

export default Third;
