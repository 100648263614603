import React from 'react';
import { Container, Row, Col } from 'reactstrap'
import nov_4 from "../../../../assets/imgs/landing2022/capsulas/nov_4.jpg"
import nov_6 from "../../../../assets/imgs/landing2022/capsulas/nov_6.jpg"
import nov_7 from "../../../../assets/imgs/landing2022/capsulas/nov_7.jpg"
import nov_8 from "../../../../assets/imgs/landing2022/capsulas/nov_8.jpg"
import nov_10 from "../../../../assets/imgs/landing2022/capsulas/nov_10.jpg"
import nov_11 from "../../../../assets/imgs/landing2022/capsulas/nov_11.jpg"

import "../../../../style/home.css"
const etnograpgic_info = [
    {
        imgs: nov_4,
        title: `Armando Martínez: "Dibujando el viento"`,
        production: "La Maroma Producciones",
        date: "Viernes 4 noviembre 2022 / 20:00 hrs",
        where: ""
    },
    {
        imgs: nov_6,
        title: `Familia Linares: "Vida y movimiento en cartón"`,
        production: "La Maroma Producciones",
        date: "Domingo 6 noviembre 2022 / 20:00 hrs.",
        place: "Facebook"
    },
    {
        imgs: nov_7,
        title: `Abraham y José Manuel Ruiz: "Historias que emanan del hueso"`,
        production: "La Maroma Producciones",
        date: "Lunes 7 noviembre 2022 / 16:00 hrs.",
        place: "Facebook"
    },
    {
        imgs: nov_8,
        title: `René Ángeles: "Conocimiento y corazón"`,
        production: "La Maroma Producciones",
        date: "Martes 8 noviembre 2022 / 20:00 hrs.",
        place: "Facebook"
    },
    {
        imgs: nov_10,
        title: `Familia García, Wilgart: "El alma saliendo a la luz en cada pieza"`,
        production: "La Maroma Producciones",
        date: "Jueves 10 noviembre 2022 / 20:00 hrs.",
        place: "Facebook"
    },
    {
        imgs: nov_11,
        title: `Angélica Reyes : "Bordando tradición"`,
        production: "La Maroma Producciones",
        date: "Viernes 11 noviembre 2022 / 16:00 hrs.",
        place: "Facebook"
    },

]
function Etnographic() {
    return (
        <Container className="d-flex flex-column justify-content-center" fluid>
            <Row className="mt-5">
                <Col>
                    <h1 className="ml-3 ml-md-0 font-weight-bold text-dark ">
                        Cápsulas etnográficas
                    </h1>
                </Col>
            </Row>
            <Row className="my-5 d-md-flex">
                {etnograpgic_info.map(({ imgs, title, production, date, place }, i) => (
                    <Col key={i} md={4} sm={12}>
                        <img src={imgs} alt={title} className="img-fluid worksimg" />
                        <h2 className="font-weight-light mt-4">
                            {title}
                        </h2>
                        <h4 className="font-weight-light">
                            {production}
                        </h4>
                        <p className="font-weight-light">
                            {date}<br/>
                            {place}
                        </p>
                    </Col>
                ))}
            </Row>
        </Container >
    )
}

export default Etnographic