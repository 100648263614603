import React from 'react';
import { Row, Col } from 'reactstrap'
import cover_violencia from "../../../../assets/imgs/landing2023/exposiciones/violencia/31.jpg";
import cover_raices from "../../../../assets/imgs/landing2023/exposiciones/raices/03.png";
import entretejidas from "../../../../assets/imgs/landing2022/exposiciones/virtuales/expo_entretejida.png"
import segunda from "../../../../assets/imgs/landing2022/exposiciones/virtuales/expo_segunda.jpg"
import tercera from "../../../../assets/imgs/landing2022/exposiciones/virtuales/expo_tercera.png"

import "../../../../style/home.css"

const virtual_info = [
    {
        imgs: cover_violencia,
        title: "30 alertas contra la violencia de genero",
        text: "Red Gráfica de Conciencias Social",
        link: "violencia-genero",
        date: "Viernes 10 Noviembre, 18:00 hrs",
        extra: "Exposición Virtual"
    },
    {
        imgs: cover_raices,
        title: "Raíces",
        text: "Aswer García",
        link: "raices",
        date: "lunes 13 noviembre 18:00 hrs",
        extra: "Exposición Virtual"
    },
    {
        imgs: entretejidas,
        title: "Arte Emergente. Colección Cinto",
        text: "El agua que te sembro 2, Carteles Mujeres, Colección 2020-2023",
        link: "arte-emergente",
        date: "viernes 17 noviembre 17:00 hrs",
        extra: "C. 14 Pte. 307, San Juan Aquiahuac, 72810 San Andrés Cholula, Pue."
    },
]
function Expos() {
    return (
        <div className="d-flex flex-column">
            <Row className="my-5 mt-md-5">
                {virtual_info.map(({ title, text, imgs, link, date, extra }, i) => (
                    <Col md={4} sm={12}>
                        <a href={link}>
                            <img src={imgs} alt={title} className="img-fluid custom-landing-img" />
                        </a>
                        <div>
                            <h2 className="font-weight-light mt-3">
                                {title}
                            </h2>
                            <h3 className="font-weight-light">
                                {text}
                            </h3>
                            <p>
                                {date}<br />
                                {extra}
                            </p>
                        </div>
                    </Col>
                ))}
            </Row>
        </div>
    )
}

export default Expos