import React from 'react';
import { Container, Row, Col } from 'reactstrap'
import "../../../style/barroquito.css"
import img1 from "../../../assets/imgs/barroquito/primera/1.png"
import img2 from "../../../assets/imgs/barroquito/primera/2.png"
import img3 from "../../../assets/imgs/barroquito/primera/3.png"
import img4 from "../../../assets/imgs/barroquito/primera/4.png"
import img5 from "../../../assets/imgs/barroquito/primera/5.png"
import img6 from "../../../assets/imgs/barroquito/primera/6.png"
import img7 from "../../../assets/imgs/barroquito/primera/7.png"
import img8 from "../../../assets/imgs/barroquito/primera/8.png"
import img9 from "../../../assets/imgs/barroquito/primera/9.png"
import img10 from "../../../assets/imgs/barroquito/primera/10.png"
import img11 from "../../../assets/imgs/barroquito/primera/11.png"
import img12 from "../../../assets/imgs/barroquito/primera/12.png"
import img13 from "../../../assets/imgs/barroquito/primera/13.png"
import img14 from "../../../assets/imgs/barroquito/primera/14.png"
import img15 from "../../../assets/imgs/barroquito/primera/15.png"
import img16 from "../../../assets/imgs/barroquito/primera/16.png"
import img17 from "../../../assets/imgs/barroquito/primera/17.png"
import img18 from "../../../assets/imgs/barroquito/primera/18.png"
import img19 from "../../../assets/imgs/barroquito/primera/19.png"
import img20 from "../../../assets/imgs/barroquito/primera/20.png"
import img21 from "../../../assets/imgs/barroquito/primera/21.png"
import img22 from "../../../assets/imgs/barroquito/primera/22.png"
import img23 from "../../../assets/imgs/barroquito/primera/23.png"
import img24 from "../../../assets/imgs/barroquito/primera/24.png"
import img25 from "../../../assets/imgs/barroquito/primera/25.png"
import img26 from "../../../assets/imgs/barroquito/primera/26.png"


const firstcontent = [
    {
    numberpiece : "Pieza 1 de 26",
    artist: "A-ha Lesly César",
    src: img1,
    socials:"https://www.facebook.com/A-ha-jeans/"
    },
    {
    numberpiece : "Pieza 2 de 26",
    artist: "Aldo Spíndola",
    src: img2,
    socials:"https://www.instagram.com/aspinco/"
    },
    {
    numberpiece : "Pieza 3 de 26",
    artist: "Andrés y Eduardo Lhima",
    src: img3,
    socials:"https://www.instagram.com/andreslhima/"
    },
    {
    numberpiece : "Pieza 4 de 26",
    artist: "Bandido Studio",
    src: img4,
    socials:"https://www.instagram.com/bandidostudio/"
    },
    {
    numberpiece : "Pieza 5 de 26",
    artist: "Canijo Conejo",
    src: img5,
    socials:"https://www.instagram.com/conejo_muerto/"
    },
    {
    numberpiece : "Pieza 6 de 26",
    artist: "César Cepeda",
    src: img6,
    socials:"https://www.instagram.com/kraneokraneo/"
    },
    {
    numberpiece : "Pieza 7 de 26",
    artist: "Dos Veintinueve",
    src: img7,
    socials:"https://www.instagram.com/dosveintinueve/"
    },
        {
    numberpiece : "Pieza 8 de 26",
    artist: "Elmer Sosa",
    src: img8,
    socials:"https://www.instagram.com/elmer1008/"
    },
    {
    numberpiece : "Pieza 9 de 26",
    artist: "El Dee",
    src: img9,
    socials:"https://www.instagram.com/el.dee/"
    },
    {
    numberpiece : "Pieza 10 de 26",
    artist: "Ezequiel Farca",
    src: img10,
    socials:"https://www.instagram.com/ezequiel_farca/"
    },
    {
    numberpiece : "Pieza 11 de 26",
    artist: "Gracmor",
    src: img11,
    socials:"https://www.instagram.com/gracmor/"
    },
    {
    numberpiece : "Pieza 12 de 26",
    artist: "Guga Murrieta",
    src: img12,
    socials:"https://www.instagram.com/gus.murrieta/"
    },
    {
    numberpiece : "Pieza 13 de 26",
    artist: "Hilda Aguilar",
    src: img13,
    socials:"https://www.instagram.com/hildaaguilarjoyas/"
    },
    {
    numberpiece : "Pieza 14 de 26",
    artist: "Israel Maldonado",
    src: img14,
    socials:"https://www.instagram.com/bluee_monkeey/"
    },
    {
    numberpiece : "Pieza 15 de 26",
    artist: "Jony Tentáculos",
    src: img15,
    socials:"https://www.instagram.com/jony_tentaculos/"
    },
    {
    numberpiece : "Pieza 16 de 26",
    artist: " John Mirón",
    src: img16,
    socials:"https://www.instagram.com/john_miron/ "
    },
    {
    numberpiece : "Pieza 17 de 26",
    artist: " Luis Reyes",
    src: img17,
    socials:"https://www.facebook.com/luis.reyezz/ "
    },
    {
    numberpiece : "Pieza 18 de 26",
    artist: " Lupilu",
    src: img18,
    socials:"https://www.instagram.com/lupilusoler/"
    },
    {
    numberpiece : "Pieza 19 de 26",
    artist: "Mxtad ",
    src: img19,
    socials:" https://www.instagram.com/mxtad/"
    },
    {
    numberpiece : "Pieza 20 de 26",
    artist: "Omar Vergara ",
    src: img20,
    socials:"https://www.instagram.com/omar_vergara_v/ "
    },
    {
    numberpiece : "Pieza 21 de 26",
    artist: "Reactiva Arquitectura ",
    src: img21,
    socials:"https://www.instagram.com/reactiva_arquitectura/"
    },
    {
    numberpiece : "Pieza 22 de 26",
    artist: "Roberto Archundia",
    src: img22,
    socials:"https://www.instagram.com/artraproberto/"
    },
    {
    numberpiece : "Pieza 23 de 26",
    artist: "Rodrigo Noriega",
    src: img23,
    socials:"https://www.instagram.com/rodrigonoriega_studio/"
    },
    {
    numberpiece : "Pieza 24 de 26",
    artist: "Todo Bien Estudio",
    src: img24,
    socials:"https://www.instagram.com/todobienestudio/"
    },
    {
    numberpiece : "Pieza 25 de 26",
    artist: "Unmarked",
    src: img25,
    socials:"https://www.instagram.com/unmarked/"
    },
    {
    numberpiece : "Pieza 26 de 26",
    artist: "Xochitlahtohua",
    src: img26,
    socials:"https://www.instagram.com/lapalabraflorida/"
    },

]
function FirstEdition(){
    return(
        <Container className="d-flex flex-column justify-content-center" fluid>
            <Row className="my-5"> 
                <Col md={1}></Col>
                <Col >
                    <h2 className=" font-weight-bold text-gray">
                        Art Toy - Barroquito
                    </h2>

                    <h6 className=" mt-4 font-weight-bold text-dark ">
                        Primera Edición
                    </h6>
                </Col>
            </Row>
            <Row md={6}>
                {firstcontent.map(({numberpiece, artist, src, socials} , i )=> (
                    <>
                    <Col md={1}></Col>
                    <Col sm={2} className="d-flex flex-column align-items-center my-4 px-md-0 px-5">
                        <img src={src} alt="barroquito" className="img-fluid barroquito_img"/>
                        <div className="d-flex flex-column justify-content-center  align-items-center text-md-left text-center">
                            <h5>
                                {numberpiece}
                            </h5>
                            <h3 className="font-weight-bold text-center ">
                                {artist}
                            </h3>
                            <a href={socials} target="_blank" rel="noopener noreferrer">
                                <h5 className="d-md-flex d-none text-pink font-weight-bold">
                                    <u>Ver más sobre el artista</u>
                                </h5>
                                <h5 className="d-md-none d-flex text-pink font-weight-bold">
                                    <u>Ver más<br/> sobre el artista</u>
                                </h5>
                            </a>
                        </div>
                    </Col>
                    <Col md={1}></Col>
                    </>
                ))}
            </Row>
        </Container>
    )
}

export default FirstEdition