import React, { useState } from "react"
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavItem,
    NavLink,
    Container,
} from "reactstrap"
import { Link } from 'react-router-dom'
import logo from "../assets/imgs/logo.png"

import "../style/navbar.css"

const Navb = (props) => {
    const [collapsed, setCollapsed] = useState(true);
    const toggleNavbar = () => setCollapsed(!collapsed);


    const barroquito = [
        {
            to: "/cuarta-edicion/",
            name: "Cuarta edición"
        },
        {
            to: "/tercera-edicion/",
            name: "Tercera edición"
        },
        {
            to: "/segunda-edicion/",
            name: "Segunda edición"
        },
        {
            to: "/primera-edicion/",
            name: "Primera edición"
        },
        {
            to: "/art-toy/",
            name: "Art Toy"
        },
    ]

    const routeLinks = [
        {
            to: "/nosotros/",
            name: "Nosotros",
        },
        {
            to: "/aliados/",
            name: "Aliados",
        },
        {
            to: "/contacto/",
            name: "Contacto",
        },
    ]

    const navbMobile = [
        {
            to: "/eventos/",
            name: "Eventos"
        },
        {
            to: "/barroquito/",
            name: "Barroquito"
        },
        {
            to: "/nosotros/",
            name: "Nosotros"
        },
        {
            to: "/aliados/",
            name: "Aliados"
        },
        {
            to: "/contacto/",
            name: "Contacto"
        },
    ]

    return (
        <>
            <Container className="d-md-none d-flex" fluid>
                <Navbar color="faded" light className="vw-100">
                    <NavbarBrand href="/" className="d-flex flex-row justify-content-center align-items-center">
                        <img
                            src={logo}
                            alt="main logo"
                            className="img-fluid logo_nav" />
                    </NavbarBrand>
                    <NavbarToggler onClick={toggleNavbar} />
                    <Collapse isOpen={!collapsed} navbar >
                        <Nav navbar>
                            {navbMobile.map(({ to, name }, i) => (
                                <NavItem
                                    to={to}
                                    tag={Link}
                                    className="nav_color my-2 text-right"
                                >
                                    {name}
                                </NavItem>
                            ))}
                        </Nav>
                    </Collapse>

                </Navbar>
            </Container>

            <Container className="d-md-flex d-none" fluid>
                <Navbar expand="lg" className="vw-100">
                    <NavbarBrand href="/" className="d-flex mr-auto flex-row align-items-center">
                        <img
                            src={logo}
                            alt="main logo"
                            className="img-fluid logo_nav" />
                    </NavbarBrand>

                    <div className="d-flex ">
                        <Nav className="d-flex flex-wrap align-items-center" navbar>
                            <NavItem tag={Link} to="/eventos" className="text-center px-2">
                                <h5 className="font-weight-bold ">Programa 2023</h5>
                            </NavItem>
                            <UncontrolledDropdown className="dropdown">
                                <DropdownToggle className="d-flex flex-row align-items-center" nav caret>
                                    <h5 className="font-weight-bold">Exposiciones</h5>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown custom_drop">
                                    {barroquito.map(({ to, name }, i) => (
                                        <DropdownItem
                                            to={to}
                                            tag={Link}
                                            className="text-center"
                                        >
                                            {name}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            {routeLinks.slice(0, 6).map(({ to, name, external }, i) => (
                                <NavItem key={i}>
                                    <NavLink
                                        className="nav_color"
                                        tag={external ? "a" : Link}
                                        to={to}
                                        href={to}>
                                        <h5 className="font-weight-bold"> {name}</h5>
                                    </NavLink>
                                </NavItem>
                            ))}
                        </Nav>
                    </div>
                </Navbar>
            </Container>
            <div style={{ borderTop: "8px solid #e4e1e6 ", width: "100vw", marginLeft: 0 }} />

        </>
    )
}

export default Navb
