import React from "react";
import { Container, Row, Col } from "reactstrap";

import objetos from "../../../assets/imgs/landing2022/home/letrados.jpg"
import cover_expos from "../../../assets/imgs/landing2022/cover_expos.jpg"
import cover_taller_ladrillos from "../../../assets/imgs/landing2022/cover_taller_ladrillos.jpg"
import cover_home from "../../../assets/imgs/landing2022/design_night.jpg"
import cover_interiorismo from "../../../assets/imgs/landing2022/cover_interiorismo.jpg"
import cover_territorio from "../../../assets/imgs/landing2022/cover_territorio.png"
import cover_loteria from "../../../assets/imgs/landing2022/cover_loteria.png"
import cover from "../../../assets/imgs/landing2022/cover_fachadas.png"
import "../../../style/home.css"

const info = [
    {
        imgs: cover_loteria,
        title: "Poetry, transform and future.",
        text: "Universidad Anáhuac Puebla, MUT Design, Ladrillos de México, Covarrubias Mármoles",
        date: "Jueves 9 Noviembre, 17:00 hrs",
        link: "pertenencia"
    },
    {
        imgs: cover_expos,
        title: "To the center of the table",
        text: "Universidad Anáhuac Puebla, EASD Valencia",
        date: "Jueves 9 Noviembre, 17:00 hrs",
        link: ""
    },
    {
        imgs: cover_taller_ladrillos,
        title: "Glass Encounters",
        text: "MUT Design, EASD Valencia",
        date: "Jueves 9 Noviembre, 17:00 hrs",
        link: ""
    },
    {
        imgs: cover,
        title: "El Trapo - Hector de la Serna, Sebastián Gaspar, Fabiola Montes e Iván Pimentel",
        text: "C. 14 Pte. 307, San Juan Aquiahuac, 72810 San Andrés Cholula, Pue.",
        date: "Sábado 11 Noviembre, 15:00 hrs",
        link: ""
    },
    {
        imgs: cover_interiorismo,
        title: "Vestigios de Julio Aibar",
        text: "C. 14 Pte. 307, San Juan Aquiahuac, 72810 San Andrés Cholula, Pue.",
        date: "Domingo 12 Noviembre, 15:00 hrs",
        link: ""
    },
    {
        imgs: cover_territorio,
        title: "El Origen Fashion Fest",
        text: "MUI. Museo Urbano Interactivo, 4 poniente, Centro Historico de Puebla",
        date: "Sábado 18 Noviembre, 17:00 hrs",
        link: ""
    },
    {
        imgs: cover_home,
        title: "Cena de Gala",
        text: "C. 14 Pte. 307, San Juan Aquiahuac, 72810 San Andrés Cholula, Pue.",
        date: "Sábado 18 Noviembre, 19:00 hrs",
        link: ""
    },
]

function Letrados(){
    return(
        <>
        <Container className="bg-letrados" fluid>
        </Container>
        
            <Container fluid>
                <Row className=" ">
                    <Col md={1} sm={12}></Col>
                    <Col md={6} sm={12}>
                        <h6 className="mt-3 font-weight-bold text-dark ">
                            Eventos
                        </h6>
                    </Col>
                </Row>
                <Row md={2} className="my-5 d-flex justify-content-center mt-md-5 px-5">
                    {info.map(({ title, text, imgs,date }, i) => (
                        <Col md={4} sm={12} key={i}>
                            <img src={imgs} alt={title} className="mt-md-5 img-fluid custom-landing-img" />
                            <div className="px-1">
                                <h2 className="font-weight-light mt-3 ">
                                    {title}
                                </h2>
                                <p className="font-weight-light">
                                    {text} <br/>
                                    {date}
                                </p>
                            </div>
                        </Col>
                    ))}
                    <Col md={1}></Col>
                </Row>
            </Container>
        </>
    )
}

export default Letrados