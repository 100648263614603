import React from 'react';
import { Layout } from '../components'
import { UsFirst } from "../components/sections/us"
import "../style/home.css"

function FirstEditionUs(){
    return(
        <Layout>
            <UsFirst/>
        </Layout>
    )
}

export default FirstEditionUs;
