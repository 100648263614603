import React from 'react';
import { Layout } from '../components'
import { Works } from "../components/sections/program2021"
import "../style/home.css"

function Workshops(){
    return(
        <Layout>
            <Works/>
        </Layout>
    )
}

export default Workshops;
