import React from "react";
import { Container, Row, Col } from "reactstrap";

function Interiorismo() {
    return (
        <>
            <Container className="bg-interiorismo" fluid>

            </Container>
            <Container className="px-md-0 px-4" fluid>
                <Row className="my-md-5 my-3">
                    <Col md={1}></Col>
                    <Col >
                        <h6 className="mt-4 font-weight-light text-dark ">
                            Una mirada al interiorismo Latinoamericano
                        </h6>
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col md={1}></Col>
                    <Col >
                        <h4>
                            En los últimos años el interiorismo latinoamericano ha logrado posicionarse en los principales
                            escaparates del mundo, gracias a propuestas osadas y creativas que rompen con el esquema de lo
                            tradicional. <br /><br />
                            El interiorista tiene el objetivo de acondicionar y optimizar los espacios para mejorar la calidad de vida
                            de quienes lo utilizan. Una profesión que requiere de conocimientos sobre arquitectura, diseño,
                            tendencias, comunicación y presupuestos, son quienes hacen realidad el arte de vivir los espacios.<br /><br />
                            En Latinoamérica se está haciendo un gran esfuerzo por parte de las asociaciones y agrupaciones que
                            se han conformado a lo largo del continente para seguir posicionando el trabajo de diseñadores y
                            diseñadoras de habla hispana que día a día demuestran su profesionalismo, demostrando que
                            estamos a la vanguardia.<br /><br />
                            Una mirada al interiorismo Latinoamericano reúne el trabajo de 20 despachos/diseñadores a través de
                            láminas de gran formato junto una descripción para comprender el trasfondo creativo de la
                            intervención de cada espacio.<br /><br />
                            Participan Guatemala, Costa Rica, Colombia, Perú, Argentina, Brasil, Chile y Puebla representando a
                            México.<br /><br />
                            <span className="font-weight-bold">Inauguración: </span> Miércoles 9 de noviembre de 2022, 11:00 hrs.<br />
                            ‘Innovaction Gym’ Tecnológico de Monterrey, Atlixcáyotl 5718, Reserva Territorial Atlixcáyotl, Puebla.
                        </h4>
                    </Col>
                    <Col md={1}></Col>
                </Row>

            </Container>
        </>
    )
}

export default Interiorismo