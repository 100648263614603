import React from 'react';
import { Container, Row, Col } from 'reactstrap'

import "../../../style/home.css"

function SanFrancisco() {
    return(
        <>
        <Container className="bg-sanfran" fluid>

        </Container>
        <Container className="d-flex flex-column justify-content-center" fluid>
            <Row className="my-5"> 
                <Col md={1}></Col>
                <Col >
                    <h2 className="text-gray font-weight-bold">
                        Programa 2021
                    </h2>

                    <h6 className="mt-4 font-weight-bold text-dark ">
                        Design Night
                    </h6>
                </Col>
            </Row>
            <Row className="mb-5">
                <Col md={1}></Col>
                <Col>
                    <h5>
                        Es la noche de las personas que formamos parte de la semana del diseño: aliados estratégicos, artesanos, artistas,
                        diseñadores y staff, nos reunimos en el lugar – espacio arquitectónico dentro o fuera de la ciudad – en donde nos
                        sentamos a la mesa para dialogar, contar nuestros retos, degustar, generar comunidad y fortalecer nuestro
                        pensamiento.<br/><br/>
                        En el evento en el que año con año buscamos reconocer a una persona, institución, colectivo, cooperativa o embajador
                        que aporta a la industria creativa y sobre todo incentiva a la inversión y el crecimiento de la economía naranja
                        (economía creativa) en la región. Design Night eres tú, somos todos, sé parte de esta noche.<br/><br/>
                        <span className="font-weight-bold">Sábado 6 noviembre 2021 / 19:00 hrs.<br/>
                        Atlixco, Puebla.</span>
                    </h5>
                </Col>
                <Col md={1}></Col>
            </Row>
        </Container>
        </>
    )
}

export default SanFrancisco