import React from 'react';
import { Container, Row, Col } from 'reactstrap'

import "../../../../style/home.css"

function OpenTypes() {
    return (
        <>
            <Container className="bg-open d-flex flex-column justify-content-center " fluid>
            </Container>
            <Container className="my-5" fluid>
                <Row className="mt-5">
                    <Col md={1}></Col>
                    <Col >
                        <h6>
                            Estudios Abiertos: talleres artesanales
                        </h6>
                    </Col>
                </Row>
                <Row>
                    <Col md={1}></Col>
                    <Col >
                        <h4 className="my-md-3 my-3">
                            Interactuar, aprender, experimentar y reconocer. Talleres artesanales en Design Week Puebla es signo
                            de estudios abiertos a estudiantes, profesionistas y público en general. En el estudio del Territorio
                            Transversal el interactuar directamente con el artesano es aprender de sus técnicas ancestrales, experimentar en su cotidiano -en su propio hábitat- y de esta manera reconocer el valor del oficio histórico,
                            del oficio de día a día y de la labor de ser la quinta o sexta generación de una tradición familiar, regional,
                            estatal y nacional. <br/>
                            El valor de las piezas está en la historia que cada artesano transmite en ellas, el valor del diseño está
                            en reconocer al artesano, su historia y técnica. El objeto está para pertenecerte. <br />
                        </h4>
                        <h1>
                            Ladrillos de México
                        </h1>
                        <h4 className="my-md-3 my-3">
                            Lunes 7 de noviembre de 2022 / 10:00 hrs. <br/>
                            36 poniente 138 Barrio de Santiago Mixquita, Cholula, Puebla.<br />
                            (Grupo cerrado)
                        </h4>
                        <h1>
                            Las ladrilleras de la Región de Cholula

                        </h1>
                        <h4 className="my-md-3 my-3">
                            La producción artesanal del barro rojo recocido en la Región de Cholula es reconocida por la mezcla
                            de arcillas de la región: tierra negra, tepetate o tierra amarilla y el barro negro. Aun cuando en Cholula
                            dejó de producirse objetos artesanales, cuestión de identidad y de memoria, Ladrillos de México
                            apuesta por la recuperación de la alfarería y de la producción contemporánea artesanal. <br /><br />
                            En estudios abiertos: talleres artesanales en Ladrillos de México es visibilizar el trabajo de un artesano,
                            de la construcción de una región basada en: casa-taller, artesanía-cotidiano, memoria-continuidad.
                            Amasar [Proceso en la fabricación de la masa de barro, que se utiliza para mezclar las diferentes arcillas, dar volumen y añadir resistencia para producir un producto final: objeto letrado con diseño]
                            <br /><br />
                            Nueva fecha añadida: 12 de noviembre de 2022, 10:00 am.
                        </h4>
                    </Col>
                    <Col md={1}></Col>
                </Row>
            </Container>
        </>
    )
}

export default OpenTypes