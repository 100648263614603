import React from 'react';
import { Layout } from '../components'
import { FirstEdition } from "../components/sections/editions"
import "../style/home.css"

function First(){
    return(
        <Layout>
            <FirstEdition/>
        </Layout>
    )
}

export default First;
