import React from 'react';
import { Layout } from '../components'
import { PueblaFoodDesign } from "../components/sections/program2022"
import "../style/home.css"

function FoodDesign(){
    return(
        <Layout>
            <PueblaFoodDesign />
        </Layout>
    )
}

export default FoodDesign;
