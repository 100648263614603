import React from 'react';
import { Layout } from '../components'
import { ContactPage } from "../components"
import "../style/home.css"

function Contact(){
    return(
        <Layout>
            <ContactPage/>
        </Layout>
    )
}

export default Contact;
