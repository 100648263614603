import React from 'react';
import { Layout } from '../components'
import { Study } from "../components/sections/program2022"
import "../style/home.css"

function Territory(){
    return(
        <Layout>
            <Study/>
        </Layout>
    )
}

export default Territory;
