import React from 'react';
import { Container, Row, Col } from 'reactstrap'
import "../../../style/home.css"

const us_info = [
    {
        link: "/cuarta-edicion-nosotros",
        edition: "Cuarta edición",
        year: "2022",
        edition_name: `"Pertenencia"`,
    },
    {
        link: "/tercera-edicion-nosotros",
        edition: "Tercera edición",
        year: "2021",
        edition_name: `"Trascender al/el origen"`,
    },
    {
        link: "/segunda-edicion-nosotros",
        edition: "Segunda edición",
        year: "2020",
        edition_name: `"El origen para trascender"`,
    },
    {
        link: "/primera-edicion-nosotros",
        edition: "Primera edición",
        year: "2019",
        edition_name: `"Conociéndonos"`,
    },
]
function Us() {
    return (
        <>
            <Container className="bg-us" fluid>
            </Container>
            <Container className="d-flex flex-column justify-content-center" fluid>
                <Row className="mt-5">
                    <Col md={1}></Col>
                    <Col >
                        <h6 className="font-weight-bold">
                            Design Week Puebla
                        </h6>
                    </Col>
                </Row>
                <Row className="my-5">
                    <Col md={1}></Col>
                    <Col>
                        <h5 >
                            Design Week Puebla (DWP) es una muestra creativa dedicada al diseño, arte y cultura del Estado de
                            Puebla. Somos un festival híbrido, nuestros eventos son realizados en diversos formatos tanto presenciales como digitales -adaptables, flexibles y cambiables- de acceso gratuito y apto para todo público. <br /><br />
                            Difundimos y reflexionamos en colectivo al originario, sus modos de vida, su población, sus usos y
                            costumbres, el proceso del creativo artesanal y el proceso del creativo profesional –diseñadores,
                            arquitectos, artistas-; con ello aportamos conocimiento, técnicas y productos con identidad: objetos
                            letrados con diseño.<br /><br />
                            Colaboramos, generamos alianzas y somos puente con artesanos, artistas, comunidades y organizaciones del sector público y privado, del sector artístico y cultural en general. Realizamos estudios
                            de campo – etnográficos para conocer, pertenecer, idear, intervenir, promover e impulsar el talento
                            poblano de manera nacional e internacional. Nuestra iniciativa es sin fines de lucro.<br /><br />
                            Para Design Week Puebla el diseño es un bien común, necesario y accesible, acompañado de un
                            discurso estético y teórico de las memorias y saberes del territorio que lo produce. Para ello se ha
                            planteado un estudio en tiempos, tejiéndose entre sí con la comunidad que nos aporta y que visibilizamos en plataforma:

                        </h5>

                    </Col>
                    <Col md={1}></Col>
                </Row>
                <Row className="d-flex justify-content-around align-items-center text-center my-5 mx-md-5">
                    {us_info.map(({ link, edition, year, edition_name }, i) => (
                        <div key={i} md={12} sm={12}>
                            <a href={link} className="text-pink">
                                <h5 className="font-weight-bold"><u>{edition}</u></h5>
                            </a>
                            <h5>{year}</h5>
                            <h5>{edition_name}</h5>
                        </div>
                    ))}
                </Row>
            </Container>
        </>
    )
}

export default Us