import React from 'react';
import { Layout } from '../components'
import { UsFourth } from "../components/sections/us"
import "../style/home.css"

function FourthEditionUs() {
    return (
        <Layout>
            <UsFourth />
        </Layout>
    )
}

export default FourthEditionUs;
