import React from 'react';
import { Layout } from '../components'
import { DesignNight } from "../components/sections/program2022/"
import "../style/home.css"

function DesignNightPage() {
    return (
        <Layout>
            <DesignNight />
        </Layout>
    )
}

export default DesignNightPage;
